import styled, { css } from 'styled-components'

export const WarpBigBox = styled.div`
  width: 1200px;
  display: flex;
`

export const ImgBigBox = styled.div`
  width: 820px;
  height: 536px;
  background-color: #fafbfc;
  position: relative;
  p {
    position: absolute;
    margin: 35px 0 0 40px;
  }
  svg {
    width: 737px;
    height: 420px;
    margin-top: 60px;
  }
`

export const WrapSmallBox = styled.div`
  ${({ theme: { color, typography } }) => css`
    width: 380px;
    height: 536px;
    display: flex;
    flex-direction: column;
    p {
      color: ${color.text};
      font-size: ${typography.textSmall};
    }
  `}
`

export const SmallBoxOne = styled.div`
  width: 380px;
  height: 269px;
  background-color: #ebf5fa;
`

export const SmallBoxTwo = styled.div`
  ${({ theme: { color } }) => css`
    width: 380px;
    height: 268px;
    background-color: ${color.panel};
  `}
`

export const SmallItemBox = styled.div`
  width: 281px;
  margin: 0px auto;
  h3 {
    margin: 40px 0 25px 0;
  }
`

export const SmallItemBoxTwo = styled.div`
  width: 294px;
  margin: 0px auto;
  h3 {
    margin: 60px 0 20px 0;
  }
`

import styled, { css } from 'styled-components'
import { Flex } from '@raysync/common/components'

export const ApplicationItem = styled.div`
  ${({ theme: { color, typography } }) => css`
    width: 270px;
    height: 300px;
    background-color: ${color.base};
    box-shadow: 0px 0px 12px 0px rgba(228, 232, 234, 1);
    text-align: center;
    svg {
      width: 48px;
      height: 48px;
      margin: 45px auto 30px;
    }
    p {
      margin: 20px 18px;
      font-size: ${typography.textSmall};
      color: ${color.text};
    }
  `}
`

export const ArchTab = styled.span`
  ${({ theme: { color, typography, spacing } }) => css`
    display: inline-block;
    padding: 0 32px;
    font-size: 20px;
    line-height: 36px;
    font-size: ${typography.textSmall};
    color: white;
    background-color: #252a3a;
    margin: ${spacing.large} 0 36px 0;
    :nth-of-type(1) {
      border-radius: 6px 0px 0px 6px;
    }
    :nth-of-type(2) {
      border-radius: 0px 6px 6px 0px;
    }
    ${p =>
      p.active &&
      css`
        background-color: ${color.secondary};
      `}
  `}
`

export const ProcessItem = styled.span`
  ${({ theme: { color, typography, spacing } }) => css`
    display: block;
    width: 380px;
    height: 180px;
    :nth-of-type(1) {
      background-color: #f5f7fb;
    }
    :nth-of-type(2) {
      background-color: #ebf5fa;
    }
    :nth-of-type(3) {
      background-color: ${color.panel};
    }
    padding: ${spacing.base} ${spacing.small} ${spacing.base} 30px;
    display: flex;
    align-items: center;
    h4 {
      line-height: 1;
      font-size: 20px;
      color: ${color.primary};
      padding-bottom: 20px;
    }
    p {
      color: ${color.text};
      font-size: ${typography.textSmall};
      line-height: 24px;
    }
    ${p =>
      p.active &&
      css`
        background: linear-gradient(90deg, #fd5c1f 0%, #ffa640 100%) !important;
        h4 {
          color: white;
        }
        p {
          color: white;
        }
      `}
  `}
`

export const ProcessContainer = styled(Flex)`
  justify-content: space-between;
  img {
    margin: 0 auto;
  }
`

import React from 'react'
import intl from 'react-intl-universal'
import { SolutionBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, Wrapper, H3 } from '@raysync/common/components'
import Layout from '../../components/layout'

import { ApplicationSection, ApplicationContainer, ArchSection } from './atoms'
import { ApplicationItem } from './one-kilometer.atoms'
import {
  WarpBigBox,
  ImgBigBox,
  WrapSmallBox,
  SmallBoxOne,
  SmallBoxTwo,
  SmallItemBox,
  SmallItemBoxTwo,
} from './enterprise-interconnection.atoms'

import Feature1 from '@raysync/common/components/svg/network/enterprise-interconnection/enterprise-interconnection-feature1'
import Feature2 from '@raysync/common/components/svg/network/enterprise-interconnection/enterprise-interconnection-feature2'
import Feature3 from '@raysync/common/components/svg/network/enterprise-interconnection/enterprise-interconnection-feature3'
import Feature4 from '@raysync/common/components/svg/network/enterprise-interconnection/enterprise-interconnection-feature4'

import Banner from '@raysync/common/components/svg/network/enterprise-interconnection/banner'

import Internet1 from '@raysync/common/components/svg/network/enterprise-interconnection/internet-framework1'
import Internet2 from '@raysync/common/components/svg/network/enterprise-interconnection/internet-framework2'

class EnterpriseInterconnection extends React.PureComponent {
  state = {
    //方案优势列表
    featureList: [
      {
        title: intl.get('network5.feature.item1.title'),
        desc: intl.get('network5.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('network5.feature.item2.title'),
        desc: intl.get('network5.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('network5.feature.item3.title'),
        desc: intl.get('network5.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('network5.feature.item4.title'),
        desc: intl.get('network5.feature.item4.desc'),
        svg: <Feature4 />,
      },
    ],
    //方案架构列表1
    InternetOne: [
      {
        desc: intl.get('network5.feature.item5.desc1'),
      },
      {
        desc: intl.get('network5.feature.item5.desc2'),
      },
      {
        desc: intl.get('network5.feature.item5.desc3'),
      },
    ],
    //方案架构列表2
    InternetTwo: [
      {
        desc: intl.get('network5.feature.item6.desc1'),
      },
      {
        desc: intl.get('network5.feature.item6.desc2'),
      },
      {
        desc: intl.get('network5.feature.item6.desc3'),
      },
    ],
    //应用场景列表1
    InternetThree: [
      {
        desc: intl.get('network5.feature.item7.desc1'),
      },
      {
        desc: intl.get('network5.feature.item7.desc2'),
      },
      {
        desc: intl.get('network5.feature.item7.desc3'),
      },
    ],
    //应用场景列表2
    InternetFour: [
      {
        desc: intl.get('network5.feature.item8.desc1'),
      },
      {
        desc: intl.get('network5.feature.item8.desc2'),
      },
    ],
  }
  render() {
    const { location } = this.props
    const { featureList, InternetOne, InternetTwo, InternetThree, InternetFour } = this.state

    return (
      <Layout pageType='network5' location={location} scaled>
        {/* 第一屏(最后一公里解决方案) */}
        <Swiper>
          <SolutionBanner type='network5' pic={<Banner />} />
        </Swiper>

        {/* 第二屏(方案架构) */}
        <ArchSection>
          <Wrapper>
            <CommonTitle bottom={40}>{intl.get('network5.arch.title')}</CommonTitle>
            <WarpBigBox>
              <ImgBigBox>
                <Internet1 />
              </ImgBigBox>
              <WrapSmallBox>
                <SmallBoxOne>
                  <SmallItemBox>
                    <H3>{intl.get('network5.feature.item5.title')}</H3>
                    {InternetOne.map((l, i) => (
                      <div key={i}>
                        <p>{l.desc}</p>
                      </div>
                    ))}
                  </SmallItemBox>
                </SmallBoxOne>
                <SmallBoxTwo>
                  <SmallItemBox>
                    <H3>{intl.get('network5.feature.item6.title')}</H3>
                    {InternetTwo.map((l, i) => (
                      <div key={i}>
                        <p>{l.desc}</p>
                      </div>
                    ))}
                  </SmallItemBox>
                </SmallBoxTwo>
              </WrapSmallBox>
            </WarpBigBox>
          </Wrapper>
        </ArchSection>

        {/* 第三屏(方案优势) */}
        <ApplicationSection>
          <Wrapper>
            <CommonTitle>{intl.get('network5.feature.title')}</CommonTitle>
            <ApplicationContainer>
              {featureList.map((l, i) => (
                <ApplicationItem key={i}>
                  <div>{l.svg}</div>
                  <div>
                    <H3>{l.title}</H3>
                    <p>{l.desc}</p>
                  </div>
                </ApplicationItem>
              ))}
            </ApplicationContainer>
          </Wrapper>
        </ApplicationSection>

        {/* 第四屏(应用场景) */}
        <ArchSection>
          <Wrapper>
            <CommonTitle bottom={40}>{intl.get('network5.scenes.title')}</CommonTitle>
            <WarpBigBox>
              <ImgBigBox>
                <p>{intl.get('network5.feature.item9.title')}</p>
                <Internet2 />
              </ImgBigBox>
              <WrapSmallBox>
                <SmallBoxOne>
                  <SmallItemBoxTwo>
                    <H3>{intl.get('network5.feature.item7.title')}</H3>
                    {InternetThree.map((l, i) => (
                      <div key={i}>
                        <p>{l.desc}</p>
                      </div>
                    ))}
                  </SmallItemBoxTwo>
                </SmallBoxOne>
                <SmallBoxTwo>
                  <SmallItemBoxTwo>
                    <H3>{intl.get('network5.feature.item8.title')}</H3>
                    {InternetFour.map((l, i) => (
                      <div key={i}>
                        <p>{l.desc}</p>
                      </div>
                    ))}
                  </SmallItemBoxTwo>
                </SmallBoxTwo>
              </WrapSmallBox>
            </WarpBigBox>
          </Wrapper>
        </ArchSection>

        {/* 第五屏(申请免费试用) */}
        <Swiper>
          <InviteBanner type='network5' />
        </Swiper>
      </Layout>
    )
  }
}

export default EnterpriseInterconnection

import React from 'react'

const SvgBanner = props => (
  <svg
    id='banner_svg__Layer_1'
    x={0}
    y={0}
    viewBox='0 0 400 400'
    xmlSpace='preserve'
    enableBackground='new 0 0 400 400'
    {...props}
  >
    <style>{'.banner_svg__st17{fill:#f3f6ff}.banner_svg__st126{fill:#d4dffc}.banner_svg__st129{fill:#012860}'}</style>
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter'
        filterUnits='userSpaceOnUse'
        x={63.06}
        y={217.6}
        width={102.5}
        height={59.2}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={63.06} y={217.6} width={102.5} height={59.2} id='banner_svg__SVGID_1_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_1_'
            filterUnits='userSpaceOnUse'
            x={63.06}
            y={217.6}
            width={102.5}
            height={59.2}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_2_'
          gradientUnits='userSpaceOnUse'
          x1={63.056}
          y1={247.199}
          x2={165.56}
          y2={247.199}
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.124} stopColor='#f6f6f6' />
          <stop offset={0.326} stopColor='#ddd' />
          <stop offset={0.582} stopColor='#b6b6b6' />
          <stop offset={0.878} stopColor='#7f7f7f' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M64.95 249.82l44.77 25.88c2.53 1.46 6.63 1.46 9.17 0l44.77-25.88c2.53-1.46 2.53-3.83 0-5.3l-44.73-25.83c-2.53-1.46-6.64-1.46-9.17 0l-44.8 25.83c-2.54 1.46-2.54 3.83-.01 5.3z'
          mask='url(#banner_svg__SVGID_1_)'
          fill='url(#banner_svg__SVGID_2_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={63.056}
      y1={247.199}
      x2={165.56}
      y2={247.199}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.517} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M64.95 249.82l44.77 25.88c2.53 1.46 6.63 1.46 9.17 0l44.77-25.88c2.53-1.46 2.53-3.83 0-5.3l-44.73-25.83c-2.53-1.46-6.64-1.46-9.17 0l-44.8 25.83c-2.54 1.46-2.54 3.83-.01 5.3z'
      fill='url(#banner_svg__SVGID_3_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_2_'
        filterUnits='userSpaceOnUse'
        x={208.42}
        y={159.93}
        width={158.52}
        height={91.55}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={208.42} y={159.93} width={158.52} height={91.55} id='banner_svg__SVGID_4_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_2_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_3_'
            filterUnits='userSpaceOnUse'
            x={208.42}
            y={159.93}
            width={158.52}
            height={91.55}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_5_'
          gradientUnits='userSpaceOnUse'
          x1={208.417}
          y1={205.702}
          x2={366.935}
          y2={205.702}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M211.35 209.75l69.24 40.03c3.91 2.26 10.26 2.26 14.17 0L364 209.76c3.91-2.26 3.91-5.93 0-8.19l-69.18-39.94c-3.92-2.26-10.26-2.26-14.18 0l-69.28 39.94c-3.92 2.25-3.92 5.92-.01 8.18z'
          mask='url(#banner_svg__SVGID_4_)'
          fill='url(#banner_svg__SVGID_5_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_6_'
      gradientUnits='userSpaceOnUse'
      x1={208.417}
      y1={205.702}
      x2={366.935}
      y2={205.702}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M211.35 209.75l69.24 40.03c3.91 2.26 10.26 2.26 14.17 0L364 209.76c3.91-2.26 3.91-5.93 0-8.19l-69.18-39.94c-3.92-2.26-10.26-2.26-14.18 0l-69.28 39.94c-3.92 2.25-3.92 5.92-.01 8.18z'
      fill='url(#banner_svg__SVGID_6_)'
    />
    <linearGradient
      id='banner_svg__SVGID_7_'
      gradientUnits='userSpaceOnUse'
      x1={233.801}
      y1={251.12}
      x2={243.142}
      y2={251.12}
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M234.2 249.36s-1.43 4.56 1.14 4.33c2.57-.24 8.04-3.19 7.8-4.33s-6.05-.13-6.05-.13l-1.84-.68-1.05.81z'
      fill='url(#banner_svg__SVGID_7_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_4_'
        filterUnits='userSpaceOnUse'
        x={200.28}
        y={256.84}
        width={108.23}
        height={62.5}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={200.28} y={256.84} width={108.23} height={62.5} id='banner_svg__SVGID_8_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_4_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_5_'
            filterUnits='userSpaceOnUse'
            x={200.28}
            y={256.84}
            width={108.23}
            height={62.5}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_9_'
          gradientUnits='userSpaceOnUse'
          x1={200.278}
          y1={288.09}
          x2={308.504}
          y2={288.09}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M202.28 290.85l47.27 27.33c2.67 1.54 7 1.54 9.68 0l47.27-27.32c2.67-1.54 2.67-4.05 0-5.59L259.27 258c-2.67-1.54-7.01-1.54-9.68 0l-47.3 27.27c-2.68 1.54-2.68 4.04-.01 5.58z'
          mask='url(#banner_svg__SVGID_8_)'
          fill='url(#banner_svg__SVGID_9_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_10_'
      gradientUnits='userSpaceOnUse'
      x1={200.278}
      y1={288.09}
      x2={308.504}
      y2={288.09}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M202.28 290.85l47.27 27.33c2.67 1.54 7 1.54 9.68 0l47.27-27.32c2.67-1.54 2.67-4.05 0-5.59L259.27 258c-2.67-1.54-7.01-1.54-9.68 0l-47.3 27.27c-2.68 1.54-2.68 4.04-.01 5.58z'
      fill='url(#banner_svg__SVGID_10_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_6_'
        filterUnits='userSpaceOnUse'
        x={86.21}
        y={298.32}
        width={102.5}
        height={59.2}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={86.21} y={298.32} width={102.5} height={59.2} id='banner_svg__SVGID_11_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_6_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_7_'
            filterUnits='userSpaceOnUse'
            x={86.21}
            y={298.32}
            width={102.5}
            height={59.2}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_12_'
          gradientUnits='userSpaceOnUse'
          x1={86.213}
          y1={327.923}
          x2={188.718}
          y2={327.923}
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.124} stopColor='#f6f6f6' />
          <stop offset={0.326} stopColor='#ddd' />
          <stop offset={0.582} stopColor='#b6b6b6' />
          <stop offset={0.878} stopColor='#7f7f7f' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M88.11 330.54l44.77 25.88c2.53 1.46 6.63 1.46 9.17 0l44.77-25.88c2.53-1.46 2.53-3.83 0-5.3l-44.73-25.83c-2.53-1.46-6.64-1.46-9.17 0l-44.8 25.83c-2.54 1.47-2.54 3.84-.01 5.3z'
          mask='url(#banner_svg__SVGID_11_)'
          fill='url(#banner_svg__SVGID_12_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_13_'
      gradientUnits='userSpaceOnUse'
      x1={86.213}
      y1={327.923}
      x2={188.718}
      y2={327.923}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.517} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M88.11 330.54l44.77 25.88c2.53 1.46 6.63 1.46 9.17 0l44.77-25.88c2.53-1.46 2.53-3.83 0-5.3l-44.73-25.83c-2.53-1.46-6.64-1.46-9.17 0l-44.8 25.83c-2.54 1.47-2.54 3.84-.01 5.3z'
      fill='url(#banner_svg__SVGID_13_)'
    />
    <path
      className='banner_svg__st17'
      d='M393.76 207.23c.42-7.96 2.09-47.93-6.36-52.8-1.23-.71-2.57-.85-3.87-.42-4.25 1.41-7.44 8.85-11.14 17.46-2.91 6.79-6.54 15.23-9.54 16.23-.29.09-.65.15-1.19-.16-4.29-2.48-4.84-22.16-5.28-37.98-.65-23.41-1.46-37.86-7.14-41.14-1.23-.71-2.55-.81-3.81-.3-4.53 1.85-7.44 11.46-11.8 27-3.36 11.97-7.55 26.87-11.87 28.63-.38.15-.87.25-1.64-.19-5.87-3.39-5.47-24.12-5.15-40.78.4-20.93.36-33.92-5.21-37.15-1.24-.72-2.59-.8-3.79-.22-4.52 2.15-6.5 13.38-9.36 31.52-1.71 10.88-4.31 27.31-7.29 28.74-.05.02-.1.05-.34-.08-2.33-1.34-4.71-12.19-2.7-32.1l-3.69-.37c-.53 5.22-2.78 31.46 4.54 35.68 1.24.72 2.58.79 3.79.22 4.51-2.15 6.49-13.37 9.35-31.5 1.72-10.88 4.31-27.33 7.29-28.75.05-.02.11-.05.34.09 3.96 2.3 3.63 19.8 3.36 33.87-.39 20.35-.75 39.58 7.01 44.06 1.6.92 3.29 1.06 4.89.41 6-2.44 9.9-16.34 14.04-31.06 2.92-10.39 6.55-23.31 9.63-24.57.12-.05.25-.1.56.08 4.3 2.48 4.85 22.2 5.29 38.03.65 23.38 1.46 37.81 7.13 41.08 1.34.77 2.8.94 4.21.47 4.58-1.52 8.07-9.66 11.77-18.28 2.78-6.47 6.24-14.52 8.9-15.41.25-.08.49-.09.85.12 4.77 2.75 5.51 30.54 4.52 49.4l3.7.17z'
    />
    <linearGradient
      id='banner_svg__SVGID_14_'
      gradientUnits='userSpaceOnUse'
      x1={76.152}
      y1={226.244}
      x2={79.273}
      y2={226.244}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M78.7 227.39c-.1 0-.2-.02-.29-.08l-1.98-1.15a.573.573 0 01-.21-.78c.16-.27.51-.37.78-.21l1.98 1.15c.27.16.37.51.21.78-.1.19-.29.29-.49.29z'
      fill='url(#banner_svg__SVGID_14_)'
    />
    <linearGradient
      id='banner_svg__SVGID_15_'
      gradientUnits='userSpaceOnUse'
      x1={81.652}
      y1={229.874}
      x2={86.317}
      y2={229.874}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M85.75 231.47c-.1 0-.2-.02-.29-.08l-3.52-2.04a.573.573 0 01-.21-.78c.16-.27.51-.37.78-.21l3.52 2.04c.27.16.37.51.21.78-.11.18-.3.29-.49.29z'
      fill='url(#banner_svg__SVGID_15_)'
    />
    <linearGradient
      id='banner_svg__SVGID_16_'
      gradientUnits='userSpaceOnUse'
      x1={88.696}
      y1={234.077}
      x2={92.388}
      y2={234.077}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M89.27 235.79a.573.573 0 01-.29-1.07l1.12-.65-1.12-.65a.573.573 0 01-.21-.78c.16-.27.51-.37.78-.21l2.83 1.64-2.83 1.64c-.09.06-.18.08-.28.08z'
      fill='url(#banner_svg__SVGID_16_)'
    />
    <linearGradient
      id='banner_svg__SVGID_17_'
      gradientUnits='userSpaceOnUse'
      x1={74.399}
      y1={240.388}
      x2={86.265}
      y2={240.388}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M74.97 244.06a.573.573 0 01-.29-1.07l3.57-2.07c.27-.16.62-.06.78.21.16.27.06.62-.21.78l-3.57 2.07a.46.46 0 01-.28.08zm7.15-4.13a.573.573 0 01-.29-1.07l3.57-2.07c.27-.16.62-.07.78.21s.06.62-.21.78l-3.57 2.07c-.09.05-.18.08-.28.08z'
      fill='url(#banner_svg__SVGID_17_)'
    />
    <linearGradient
      id='banner_svg__SVGID_18_'
      gradientUnits='userSpaceOnUse'
      x1={68.844}
      y1={247.271}
      x2={71.968}
      y2={247.271}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M69.42 249.56a.57.57 0 01-.57-.57v-2.62l2.26-1.31c.27-.16.62-.06.78.21.16.27.06.62-.21.78l-1.69.98v1.96c0 .31-.26.57-.57.57z'
      fill='url(#banner_svg__SVGID_18_)'
    />
    <linearGradient
      id='banner_svg__SVGID_19_'
      gradientUnits='userSpaceOnUse'
      x1={68.839}
      y1={255.108}
      x2={69.985}
      y2={255.108}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M69.41 257.72a.57.57 0 01-.57-.57v-4.08a.57.57 0 111.14 0v4.08c0 .32-.25.57-.57.57z'
      fill='url(#banner_svg__SVGID_19_)'
    />
    <linearGradient
      id='banner_svg__SVGID_20_'
      gradientUnits='userSpaceOnUse'
      x1={68.836}
      y1={262.374}
      x2={69.98}
      y2={262.374}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M69.41 264.09a.57.57 0 01-.57-.57v-2.29a.57.57 0 111.14 0v2.29c0 .31-.26.57-.57.57z'
      fill='url(#banner_svg__SVGID_20_)'
    />
    <linearGradient
      id='banner_svg__SVGID_21_'
      gradientUnits='userSpaceOnUse'
      x1={84.562}
      y1={221.832}
      x2={87.624}
      y2={221.832}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M87.05 223.03c-.11 0-.21-.03-.31-.09l-1.92-1.24a.569.569 0 01-.17-.79c.17-.26.53-.34.79-.17l1.92 1.24a.569.569 0 01-.31 1.05z'
      fill='url(#banner_svg__SVGID_21_)'
    />
    <linearGradient
      id='banner_svg__SVGID_22_'
      gradientUnits='userSpaceOnUse'
      x1={90.271}
      y1={231.804}
      x2={128.944}
      y2={231.804}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M128.37 239.27c-.1 0-.2-.02-.29-.08l-3.91-2.26a.573.573 0 01-.21-.78c.16-.27.51-.37.78-.21l3.91 2.26c.27.16.37.51.21.78-.1.19-.29.29-.49.29zm-7.82-4.51c-.1 0-.19-.02-.28-.08l-.09-.05-4.15-.8a.57.57 0 11.22-1.12l4.34.83.25.15c.27.16.37.51.21.78-.11.18-.3.29-.5.29zm-8.84-1.77c-.04 0-.07 0-.11-.01l-4.43-.85a.57.57 0 11.22-1.12l4.43.85a.57.57 0 01-.11 1.13zm-8.87-1.7c-.04 0-.07 0-.11-.01l-4.41-.85-.21-.13a.569.569 0 01-.17-.79c.17-.26.53-.34.79-.17l.02.01 4.19.81c.31.06.51.36.45.67a.55.55 0 01-.55.46zm-8.21-3.36c-.11 0-.21-.03-.31-.09l-3.79-2.45a.569.569 0 01-.17-.79c.17-.26.53-.34.79-.17l3.79 2.45a.569.569 0 01-.31 1.05z'
      fill='url(#banner_svg__SVGID_22_)'
    />
    <linearGradient
      id='banner_svg__SVGID_23_'
      gradientUnits='userSpaceOnUse'
      x1={131.71}
      y1={241.53}
      x2={134.834}
      y2={241.53}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M134.26 242.67c-.1 0-.2-.02-.29-.08l-1.98-1.14a.573.573 0 01-.21-.78c.16-.27.51-.37.78-.21l1.98 1.14c.27.16.37.51.21.78-.1.19-.29.29-.49.29z'
      fill='url(#banner_svg__SVGID_23_)'
    />
    <linearGradient
      id='banner_svg__SVGID_24_'
      gradientUnits='userSpaceOnUse'
      x1={127.645}
      y1={196.807}
      x2={130.806}
      y2={196.807}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M130.23 197.92c-.09 0-.18-.02-.27-.07l-2.02-1.07a.576.576 0 01-.24-.77c.15-.28.49-.38.77-.24l2.02 1.07c.28.15.38.49.24.77-.09.2-.29.31-.5.31z'
      fill='url(#banner_svg__SVGID_24_)'
    />
    <linearGradient
      id='banner_svg__SVGID_25_'
      gradientUnits='userSpaceOnUse'
      x1={133.702}
      y1={202.444}
      x2={173.23}
      y2={202.444}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M172.66 205.96c-.1 0-.19-.02-.28-.08l-3.97-2.27a.573.573 0 01-.21-.78c.16-.27.51-.37.78-.21l3.97 2.27a.573.573 0 01-.29 1.07zm-29.69-1.18l-.89-.47a.576.576 0 01-.24-.77c.15-.28.49-.38.77-.24l.54.29 3.51-.62c.31-.06.61.15.66.46a.57.57 0 01-.46.66l-3.89.69zm8.31-1.48a.57.57 0 01-.1-1.13l4.51-.79a.57.57 0 01.2 1.12l-4.51.79c-.03.01-.07.01-.1.01zm-12.97-1.08c-.09 0-.18-.02-.27-.07L134 200a.576.576 0 01-.24-.77c.15-.28.49-.38.77-.24l4.04 2.15c.28.15.38.49.24.77-.1.2-.29.31-.5.31zm21.98-.51a.57.57 0 01-.1-1.13l3.97-.7.83.48a.57.57 0 11-.57.99l-.48-.27-3.56.63h-.09z'
      fill='url(#banner_svg__SVGID_25_)'
    />
    <linearGradient
      id='banner_svg__SVGID_26_'
      gradientUnits='userSpaceOnUse'
      x1={176.06}
      y1={208.231}
      x2={179.188}
      y2={208.231}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M178.62 209.37c-.1 0-.19-.02-.28-.08l-1.98-1.13a.573.573 0 01-.21-.78c.16-.27.51-.37.78-.21l1.98 1.13a.573.573 0 01-.29 1.07z'
      fill='url(#banner_svg__SVGID_26_)'
    />
    <linearGradient
      id='banner_svg__SVGID_27_'
      gradientUnits='userSpaceOnUse'
      x1={171.329}
      y1={171.535}
      x2={174.467}
      y2={171.535}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M173.89 172.67c-.09 0-.19-.02-.28-.07l-1.99-1.12a.572.572 0 01-.22-.78c.15-.28.5-.37.78-.22l1.99 1.12a.572.572 0 01-.28 1.07z'
      fill='url(#banner_svg__SVGID_27_)'
    />
    <linearGradient
      id='banner_svg__SVGID_28_'
      gradientUnits='userSpaceOnUse'
      x1={176.845}
      y1={175.053}
      x2={181.509}
      y2={175.053}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M180.94 176.61c-.09 0-.19-.02-.28-.07l-3.52-1.97a.572.572 0 01-.22-.78c.15-.28.5-.37.78-.22l3.52 1.97a.572.572 0 01-.28 1.07z'
      fill='url(#banner_svg__SVGID_28_)'
    />
    <linearGradient
      id='banner_svg__SVGID_29_'
      gradientUnits='userSpaceOnUse'
      x1={183.887}
      y1={179.714}
      x2={187.024}
      y2={179.714}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M186.45 181.99a.57.57 0 01-.57-.57v-1.95l-1.7-.95a.572.572 0 01-.22-.78c.15-.27.5-.37.78-.22l2.29 1.28v2.62c-.01.31-.26.57-.58.57z'
      fill='url(#banner_svg__SVGID_29_)'
    />
    <linearGradient
      id='banner_svg__SVGID_30_'
      gradientUnits='userSpaceOnUse'
      x1={185.881}
      y1={187.608}
      x2={189.003}
      y2={187.608}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M188.43 189.9c-.1 0-.2-.02-.29-.08l-2.26-1.31v-2.62a.57.57 0 111.14 0v1.96l1.69.98a.573.573 0 01-.28 1.07z'
      fill='url(#banner_svg__SVGID_30_)'
    />
    <linearGradient
      id='banner_svg__SVGID_31_'
      gradientUnits='userSpaceOnUse'
      x1={191.203}
      y1={192.224}
      x2={195.69}
      y2={192.224}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M195.12 193.76c-.1 0-.2-.02-.29-.08l-3.34-1.93a.573.573 0 01-.21-.78c.16-.27.51-.37.78-.21l3.34 1.93c.27.16.37.51.21.78-.1.19-.29.29-.49.29z'
      fill='url(#banner_svg__SVGID_31_)'
    />
    <linearGradient
      id='banner_svg__SVGID_32_'
      gradientUnits='userSpaceOnUse'
      x1={197.89}
      y1={195.697}
      x2={201.012}
      y2={195.697}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M200.44 196.84c-.1 0-.2-.02-.29-.08l-1.98-1.14a.573.573 0 01-.21-.78c.16-.27.51-.37.78-.21l1.98 1.14c.27.16.37.51.21.78-.1.19-.29.29-.49.29z'
      fill='url(#banner_svg__SVGID_32_)'
    />
    <linearGradient
      id='banner_svg__SVGID_33_'
      gradientUnits='userSpaceOnUse'
      x1={178.602}
      y1={167.357}
      x2={181.7}
      y2={167.357}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M181.13 168.52c-.1 0-.2-.03-.3-.08l-1.96-1.18a.56.56 0 01-.19-.78.57.57 0 01.79-.19l1.96 1.18c.27.16.36.52.19.79-.11.16-.3.26-.49.26z'
      fill='url(#banner_svg__SVGID_33_)'
    />
    <linearGradient
      id='banner_svg__SVGID_34_'
      gradientUnits='userSpaceOnUse'
      x1={184.354}
      y1={175.273}
      x2={231.594}
      y2={175.273}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M231.02 180.87c-.1 0-.19-.02-.28-.08l-3.85-2.22a.573.573 0 01-.21-.78c.16-.27.51-.37.78-.21l3.85 2.22c.27.16.37.51.21.78-.11.18-.3.29-.5.29zm-7.69-4.44c-.1 0-.19-.02-.29-.08l-.92-.53h-3.08a.57.57 0 110-1.14h3.38l1.18.68c.27.16.37.51.21.78-.09.19-.28.29-.48.29zm-8.72-.61h-4.44a.57.57 0 110-1.14h4.44c.32 0 .57.26.57.57 0 .32-.25.57-.57.57zm-8.88 0h-4.44a.57.57 0 110-1.14h4.44a.57.57 0 110 1.14zm-8.88 0H193l-.78-.47a.568.568 0 01-.19-.79c.16-.27.51-.36.79-.19l.5.31h3.53a.57.57 0 110 1.14zm-8.13-2.7c-.1 0-.2-.03-.3-.08l-3.8-2.3a.559.559 0 01-.19-.78.56.56 0 01.78-.19l3.8 2.3c.27.16.36.51.19.78a.53.53 0 01-.48.27z'
      fill='url(#banner_svg__SVGID_34_)'
    />
    <linearGradient
      id='banner_svg__SVGID_35_'
      gradientUnits='userSpaceOnUse'
      x1={234.297}
      y1={183.084}
      x2={237.421}
      y2={183.084}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M236.85 184.23c-.1 0-.19-.02-.28-.08l-1.98-1.14a.573.573 0 01-.21-.78c.16-.27.51-.37.78-.21l1.98 1.14c.27.16.37.51.21.78-.11.18-.3.29-.5.29z'
      fill='url(#banner_svg__SVGID_35_)'
    />
    <linearGradient
      id='banner_svg__SVGID_36_'
      gradientUnits='userSpaceOnUse'
      x1={200.196}
      y1={154.841}
      x2={203.309}
      y2={154.841}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M202.74 155.99c-.1 0-.2-.03-.29-.08l-1.97-1.16a.57.57 0 01.58-.98l1.97 1.16a.57.57 0 01-.29 1.06z'
      fill='url(#banner_svg__SVGID_36_)'
    />
    <linearGradient
      id='banner_svg__SVGID_37_'
      gradientUnits='userSpaceOnUse'
      x1={205.599}
      y1={158.453}
      x2={210.175}
      y2={158.453}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M209.6 160.04c-.1 0-.2-.03-.29-.08l-3.43-2.02a.57.57 0 01.58-.98l3.43 2.02a.57.57 0 01-.29 1.06z'
      fill='url(#banner_svg__SVGID_37_)'
    />
    <linearGradient
      id='banner_svg__SVGID_38_'
      gradientUnits='userSpaceOnUse'
      x1={212.465}
      y1={162.11}
      x2={217.557}
      y2={162.11}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M215 163.31l-2.26-1.33a.57.57 0 01.58-.98l1.68.99 1.69-.98c.27-.16.62-.06.78.21.16.27.06.62-.21.78l-2.26 1.31z'
      fill='url(#banner_svg__SVGID_38_)'
    />
    <linearGradient
      id='banner_svg__SVGID_39_'
      gradientUnits='userSpaceOnUse'
      x1={220.728}
      y1={154.638}
      x2={254.22}
      y2={154.638}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M221.3 159.58a.573.573 0 01-.29-1.07l4.31-2.49c.27-.16.62-.06.78.21.16.27.06.62-.21.78l-4.31 2.49c-.08.05-.18.08-.28.08zm8.63-4.99a.573.573 0 01-.29-1.07l4.31-2.49c.27-.16.62-.06.78.21.16.27.06.62-.21.78l-4.31 2.49c-.09.06-.18.08-.28.08zm23.72-1.93h-.07l-4.95-.61a.575.575 0 01.14-1.14l4.95.61c.31.04.54.32.5.64a.58.58 0 01-.57.5zm-9.89-1.21h-.07l-4.95-.61a.575.575 0 01.14-1.14l4.95.61c.31.04.54.32.5.64a.58.58 0 01-.57.5z'
      fill='url(#banner_svg__SVGID_39_)'
    />
    <linearGradient
      id='banner_svg__SVGID_40_'
      gradientUnits='userSpaceOnUse'
      x1={258.023}
      y1={152.84}
      x2={261.434}
      y2={152.84}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M260.86 153.55h-.07l-2.27-.28a.575.575 0 01-.5-.64c.04-.31.32-.53.64-.5l2.27.28a.572.572 0 01-.07 1.14z'
      fill='url(#banner_svg__SVGID_40_)'
    />
    <linearGradient
      id='banner_svg__SVGID_41_'
      gradientUnits='userSpaceOnUse'
      x1={156.199}
      y1={180.299}
      x2={159.333}
      y2={180.299}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M158.76 181.43c-.1 0-.19-.02-.28-.07l-1.99-1.12a.583.583 0 01-.22-.78c.16-.27.5-.37.78-.22l1.99 1.12c.27.16.37.5.22.78a.57.57 0 01-.5.29z'
      fill='url(#banner_svg__SVGID_41_)'
    />
    <linearGradient
      id='banner_svg__SVGID_42_'
      gradientUnits='userSpaceOnUse'
      x1={161.763}
      y1={185.904}
      x2={173.624}
      y2={185.904}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M173.05 189.5c-.1 0-.19-.02-.28-.07l-3.57-2.02a.583.583 0 01-.22-.78c.16-.27.5-.37.78-.22l3.57 2.02c.27.16.37.5.22.78-.1.19-.3.29-.5.29zm-7.14-4.03c-.1 0-.19-.02-.28-.07l-3.57-2.02a.583.583 0 01-.22-.78c.16-.27.5-.37.78-.22l3.57 2.02c.27.16.37.5.22.78-.11.18-.3.29-.5.29z'
      fill='url(#banner_svg__SVGID_42_)'
    />
    <linearGradient
      id='banner_svg__SVGID_43_'
      gradientUnits='userSpaceOnUse'
      x1={176.054}
      y1={191.508}
      x2={179.188}
      y2={191.508}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M178.62 192.64c-.1 0-.19-.02-.28-.07l-1.99-1.12a.583.583 0 01-.22-.78c.15-.27.5-.37.78-.22l1.99 1.12c.27.16.37.5.22.78a.57.57 0 01-.5.29z'
      fill='url(#banner_svg__SVGID_43_)'
    />
    <path
      className='banner_svg__st17'
      d='M31.43 229.35c.41 1.4.93 2.72 1.6 3.95.05.1.1.21.16.31 1.06 1.88 2.34 3.4 3.82 4.57l.04.03 1.08.77c4.77 3.03 11.11 2.78 17.43-.64l1.39-.8 53.76-31.13 7.31-4.23.01-.01 60.22-34.87 3.38-1.96 31.5-18.24c.04-.02.09-.05.14-.07 2.94-1.63 5.88-3.95 8.64-6.95 1.96-2.12 3.69-4.41 5.19-6.85 3.23-5.2 5.39-10.94 6.33-16.48 1.18-6.8.54-13.33-2.21-18.24-1.33-2.38-3.05-4.18-5.04-5.41l-.02-.02c-1.67-1.03-3.53-1.67-5.51-1.92-2.53-.31-5.26.02-8.05.98-.4.14-.79.28-1.19.44-3.07 1.25-6.18 3.25-9.15 5.99-2.63-3.61-6.04-5.96-9.91-7.08-8.91-2.56-20.25 1.41-30.14 11.62-.92-4.75-2.36-9.21-4.36-13.27-.36-.73-.73-1.44-1.13-2.14-3.32-5.92-7.63-10.37-12.62-13.37l-.02-.01c-1.15-.69-2.34-1.3-3.57-1.84a33.282 33.282 0 00-8.96-2.49c-15.52-2.08-34.2 5.89-50.45 23.48-23.8 25.78-34.46 63.61-26.79 90.14-3.98 1.54-8.06 4.37-11.82 8.44-.67.72-1.31 1.46-1.92 2.23-8.48 10.53-12.11 24.84-9.14 35.07z'
    />
    <linearGradient
      id='banner_svg__SVGID_44_'
      gradientUnits='userSpaceOnUse'
      x1={157.858}
      y1={93.087}
      x2={192.36}
      y2={93.087}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M157.86 89.83c2 4.06 3.44 8.52 4.36 13.27 9.89-10.21 21.23-14.18 30.14-11.62l-.84-.6c-7.4-10.15-21.01-10.32-33.66-1.05z'
      fill='url(#banner_svg__SVGID_44_)'
    />
    <linearGradient
      id='banner_svg__SVGID_45_'
      gradientUnits='userSpaceOnUse'
      x1={191.52}
      y1={90.969}
      x2={226.164}
      y2={90.969}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M191.52 90.9l.84.6c3.87 1.12 7.28 3.47 9.91 7.08 2.97-2.74 6.09-4.74 9.15-5.99.4-.16.79-.31 1.19-.44 2.79-.96 5.52-1.29 8.05-.98 1.98.25 3.84.88 5.51 1.92l-9.44-6.76-1.14-.82c-4.26-2.73-9.8-2.82-15.45-.36-2.9 1.25-5.83 3.17-8.62 5.75z'
      fill='url(#banner_svg__SVGID_45_)'
    />
    <linearGradient
      id='banner_svg__SVGID_46_'
      gradientUnits='userSpaceOnUse'
      x1={41.117}
      y1={122.8}
      x2={144.086}
      y2={122.8}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M43.57 175.93l10.74 7.68c-7.67-26.54 2.99-64.36 26.79-90.14 16.24-17.59 34.92-25.56 50.45-23.48 3.13.42 6.14 1.25 8.96 2.49 1.22.54 2.42 1.15 3.57 1.84l-7.89-5.74-.97-.71c-17.04-11.91-43.13-5.59-64.86 17.93-23.8 25.78-34.45 63.6-26.79 90.13z'
      fill='url(#banner_svg__SVGID_46_)'
    />
    <linearGradient
      id='banner_svg__SVGID_47_'
      gradientUnits='userSpaceOnUse'
      x1={19.661}
      y1={207.051}
      x2={54.318}
      y2={207.051}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M22.45 225.93c1.25 2.23 2.84 3.96 4.67 5.18v.01l9.9 7.05c-1.48-1.16-2.77-2.69-3.82-4.57-.06-.1-.11-.21-.16-.31-.67-1.22-1.19-2.55-1.6-3.95-2.97-10.23.66-24.54 9.15-35.08.61-.76 1.25-1.51 1.92-2.23 3.76-4.07 7.84-6.89 11.82-8.44l-10.74-7.68c-3.98 1.55-8.06 4.37-11.82 8.44-.67.72-1.31 1.47-1.92 2.23-9.77 12.12-13.1 29.19-7.4 39.35z'
      fill='url(#banner_svg__SVGID_47_)'
    />
    <linearGradient
      id='banner_svg__SVGID_48_'
      gradientUnits='userSpaceOnUse'
      x1={90.675}
      y1={171.381}
      x2={106.415}
      y2={171.381}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_48_)'
      d='M105.27 209.55l-.04-42.41-14.55-9.5v-24.43h1.14v23.81l14.56 9.51.04 42.35z'
    />
    <linearGradient
      id='banner_svg__SVGID_49_'
      gradientUnits='userSpaceOnUse'
      x1={127.073}
      y1={139.857}
      x2={135.496}
      y2={139.857}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_49_)'
      d='M128.22 196.27l-1.15.66v-42.8l7.28-20.99V82.78h1.15v50.55l-7.28 20.99z'
    />
    <linearGradient
      id='banner_svg__SVGID_50_'
      gradientUnits='userSpaceOnUse'
      x1={116.173}
      y1={160.861}
      x2={117.316}
      y2={160.861}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_50_)' d='M117.32 202.58l-1.15.66v-84.76h1.15z' />
    <linearGradient
      id='banner_svg__SVGID_51_'
      gradientUnits='userSpaceOnUse'
      x1={97.955}
      y1={122.728}
      x2={106.415}
      y2={122.728}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_51_)' d='M106.42 150.05h-1.15v-16.69l-7.32-12.6V95.41h1.15v25.04l7.32 12.61z' />
    <linearGradient
      id='banner_svg__SVGID_52_'
      gradientUnits='userSpaceOnUse'
      x1={144.97}
      y1={152.073}
      x2={146.113}
      y2={152.073}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_52_)' d='M144.97 135.26h1.14v33.62h-1.14z' />
    <linearGradient
      id='banner_svg__SVGID_53_'
      gradientUnits='userSpaceOnUse'
      x1={61.576}
      y1={205.049}
      x2={77.258}
      y2={205.049}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_53_)'
      d='M77.26 225.7l-1.14.73v-8.95l-14.54-16.82v-16.99h1.14v16.57l14.54 16.81z'
    />
    <linearGradient
      id='banner_svg__SVGID_54_'
      gradientUnits='userSpaceOnUse'
      x1={83.99}
      y1={198.555}
      x2={91.818}
      y2={198.555}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_54_)'
      d='M85.13 221.21l-1.14.66v-25.41l6.69-12.94v-8.28h1.14v8.55l-6.69 12.94z'
    />
    <linearGradient
      id='banner_svg__SVGID_55_'
      gradientUnits='userSpaceOnUse'
      x1={156.199}
      y1={146.124}
      x2={171.901}
      y2={146.124}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_55_)'
      d='M171.9 170.98l-1.14.66v-17.19l-14.56-16.82v-17.02h1.14v16.59l14.56 16.83z'
    />
    <linearGradient
      id='banner_svg__SVGID_56_'
      gradientUnits='userSpaceOnUse'
      x1={178.03}
      y1={135.612}
      x2={186.453}
      y2={135.612}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_56_)' d='M179.17 166.77l-1.14.66v-25.91l7.28-21.01V103.8h1.14v16.91l-7.28 21z' />
    <linearGradient
      id='banner_svg__SVGID_57_'
      gradientUnits='userSpaceOnUse'
      x1={5.826}
      y1={151.878}
      x2={26.744}
      y2={151.878}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M26.74 135.86c-.26-.08-.55-.07-.83.04h-.01c-.05.03-.1.05-.15.08l-.77.45-16.15 9.33c-.86.5-1.39 1.42-1.39 2.41l-.01 17.44v1.42c0 .39.13.73.35 1.01.17.23.41.41.68.52L7.01 168l-.18-.07v-.01c-.57-.24-1-.8-1-1.51l.01-18.87c0-.99.53-1.91 1.39-2.41l16.91-9.77c.37-.21.77-.22 1.11-.08l.06.02 1.43.56z'
      fill='url(#banner_svg__SVGID_57_)'
    />
    <path
      className='banner_svg__st17'
      d='M27.66 156.67c0 .96-.51 1.85-1.35 2.33l-16.4 9.46c-.42.25-.88.27-1.29.15l-.15-.06c-.27-.11-.51-.29-.68-.52-.22-.27-.35-.62-.35-1.01v-1.42l.01-17.44c0-.99.53-1.91 1.39-2.41l16.15-9.33.77-.45c.05-.03.1-.05.15-.08h.01c.27-.11.57-.12.83-.04h.01l.13.05c.44.18.77.62.77 1.17v19.6z'
    />
    <linearGradient
      id='banner_svg__SVGID_58_'
      gradientUnits='userSpaceOnUse'
      x1={33.009}
      y1={78.669}
      x2={53.927}
      y2={78.669}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M53.93 62.65c-.26-.08-.55-.07-.83.04h-.01c-.05.03-.1.05-.15.08l-.77.45-16.15 9.33c-.86.5-1.39 1.42-1.39 2.41v18.86c0 .39.13.73.35 1.01.17.23.41.41.68.52l-1.47-.55-.18-.07v-.01c-.57-.24-1-.8-1-1.51l.01-18.87c0-.99.53-1.91 1.39-2.41l16.91-9.77c.37-.21.77-.22 1.11-.08l.06.02 1.44.55z'
      fill='url(#banner_svg__SVGID_58_)'
    />
    <path
      className='banner_svg__st17'
      d='M54.85 83.46c0 .96-.51 1.85-1.35 2.33l-16.4 9.46c-.42.25-.88.27-1.29.15l-.15-.06c-.27-.11-.51-.29-.68-.52-.22-.27-.35-.62-.35-1.01V92.4l.01-17.44c0-.99.53-1.91 1.39-2.41l16.15-9.33.77-.45c.05-.03.1-.05.15-.08h.01c.27-.11.57-.12.83-.04h.01l.13.05c.44.18.77.62.77 1.17v19.59z'
    />
    <linearGradient
      id='banner_svg__SVGID_59_'
      gradientUnits='userSpaceOnUse'
      x1={87.607}
      y1={38.51}
      x2={108.525}
      y2={38.51}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M108.52 22.49c-.26-.08-.55-.07-.83.04h-.01c-.05.03-.1.05-.15.08l-.77.45-16.15 9.33c-.86.5-1.39 1.42-1.39 2.41l-.01 17.44v1.42c0 .39.13.73.35 1.01.17.23.41.41.68.52l-1.47-.55-.18-.07v-.01c-.57-.24-1-.8-1-1.51l.01-18.87c0-.99.53-1.91 1.39-2.41L105.92 22c.37-.21.77-.22 1.11-.08l.06.02 1.43.55z'
      fill='url(#banner_svg__SVGID_59_)'
    />
    <path
      className='banner_svg__st17'
      d='M109.44 43.3c0 .96-.51 1.85-1.35 2.33l-16.4 9.46c-.42.25-.88.27-1.29.15l-.15-.06c-.27-.11-.51-.29-.68-.52-.22-.27-.35-.62-.35-1.01v-1.42l.01-17.44c0-.99.53-1.91 1.39-2.41l16.15-9.33.77-.45c.05-.03.1-.05.15-.08h.01c.27-.11.57-.12.83-.04h.01l.13.05c.44.18.77.62.77 1.17v19.6z'
    />
    <linearGradient
      id='banner_svg__SVGID_60_'
      gradientUnits='userSpaceOnUse'
      x1={146.939}
      y1={49.09}
      x2={167.856}
      y2={49.09}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M167.86 33.07c-.26-.08-.55-.07-.83.04h-.01c-.05.03-.1.05-.15.08l-.77.45-16.15 9.33c-.86.5-1.39 1.42-1.39 2.41l-.01 17.44v1.42c0 .39.13.73.35 1.01.17.23.41.41.68.52l-1.47-.55-.18-.07v-.01c-.57-.24-1-.8-1-1.51l.01-18.87c0-.99.53-1.91 1.39-2.41l16.91-9.77c.37-.21.77-.22 1.11-.08l.06.02 1.45.55z'
      fill='url(#banner_svg__SVGID_60_)'
    />
    <path
      className='banner_svg__st17'
      d='M168.78 53.88c0 .96-.51 1.85-1.35 2.33l-16.4 9.46c-.42.25-.88.27-1.29.15l-.15-.06c-.27-.11-.51-.29-.68-.52-.22-.27-.35-.62-.35-1.01v-1.42l.01-17.44c0-.99.53-1.91 1.39-2.41l16.15-9.33.77-.45c.05-.03.1-.05.15-.08h.01c.27-.11.57-.12.83-.04h.01l.13.05c.44.18.77.62.77 1.17v19.6z'
    />
    <linearGradient
      id='banner_svg__SVGID_61_'
      gradientUnits='userSpaceOnUse'
      x1={202.148}
      y1={60.097}
      x2={223.066}
      y2={60.097}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M223.07 44.08c-.26-.08-.55-.07-.83.04h-.01c-.05.03-.1.05-.15.08l-.77.45-16.15 9.33c-.86.5-1.39 1.42-1.39 2.41l-.01 17.44v1.42c0 .39.13.73.35 1.01.17.23.41.41.68.52l-1.47-.55-.18-.07v-.01c-.57-.24-1-.8-1-1.51l.01-18.87c0-.99.53-1.91 1.39-2.41l16.91-9.77c.37-.21.77-.22 1.11-.08l.06.02 1.45.55z'
      fill='url(#banner_svg__SVGID_61_)'
    />
    <path
      className='banner_svg__st17'
      d='M223.99 64.89c0 .96-.51 1.85-1.35 2.33l-16.4 9.46c-.42.25-.88.27-1.29.15l-.15-.06c-.27-.11-.51-.29-.68-.52-.22-.27-.35-.62-.35-1.01v-1.42l.01-17.44c0-.99.53-1.91 1.39-2.41l16.15-9.33.77-.45c.05-.03.1-.05.15-.08h.01c.27-.11.57-.12.83-.04h.01l.13.05c.44.18.77.62.77 1.17v19.6z'
    />
    <linearGradient
      id='banner_svg__SVGID_62_'
      gradientUnits='userSpaceOnUse'
      x1={242.661}
      y1={87.637}
      x2={263.578}
      y2={87.637}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M263.58 71.62c-.26-.08-.55-.07-.83.04h-.01c-.05.03-.1.05-.15.08l-.77.45-16.15 9.33c-.86.5-1.39 1.42-1.39 2.41l-.01 17.44v1.42c0 .39.13.73.35 1.01.17.23.41.41.68.52l-1.47-.55-.18-.07v-.01c-.57-.24-1-.8-1-1.51l.01-18.87c0-.99.53-1.91 1.39-2.41l16.91-9.77c.37-.21.77-.22 1.11-.08l.06.02 1.45.55z'
      fill='url(#banner_svg__SVGID_62_)'
    />
    <path
      className='banner_svg__st17'
      d='M264.5 92.43c0 .96-.51 1.85-1.35 2.33l-16.4 9.46c-.42.25-.88.27-1.29.15l-.15-.06c-.27-.11-.51-.29-.68-.52-.22-.27-.35-.62-.35-1.01v-1.42l.01-17.44c0-.99.53-1.91 1.39-2.41l16.15-9.33.77-.45c.05-.03.1-.05.15-.08h.01c.27-.11.57-.12.83-.04h.01l.13.05c.44.18.77.62.77 1.17v19.6z'
    />
    <linearGradient
      id='banner_svg__SVGID_63_'
      gradientUnits='userSpaceOnUse'
      x1={96.051}
      y1={92.677}
      x2={101.001}
      y2={92.677}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M100.63 90.43c.33.47.43 1.11.34 1.78-.07.55-.28 1.12-.61 1.65-.15.25-.33.48-.53.7-1.16 1.24-2.69 1.41-3.41.38-.72-1.03-.36-2.88.81-4.12 1.16-1.26 2.69-1.43 3.4-.39z'
      fill='url(#banner_svg__SVGID_63_)'
    />
    <linearGradient
      id='banner_svg__SVGID_64_'
      gradientUnits='userSpaceOnUse'
      x1={88.771}
      y1={130.308}
      x2={93.722}
      y2={130.308}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M93.35 128.06c.33.47.43 1.11.34 1.78-.07.55-.28 1.12-.61 1.65-.15.25-.33.48-.53.7-1.16 1.24-2.69 1.41-3.41.38-.72-1.03-.36-2.88.81-4.12 1.16-1.26 2.69-1.43 3.4-.39z'
      fill='url(#banner_svg__SVGID_64_)'
    />
    <linearGradient
      id='banner_svg__SVGID_65_'
      gradientUnits='userSpaceOnUse'
      x1={103.33}
      y1={152.95}
      x2={108.281}
      y2={152.95}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M107.91 150.7c.33.47.43 1.11.34 1.78-.07.55-.28 1.12-.61 1.65-.15.25-.33.48-.53.7-1.16 1.24-2.69 1.41-3.41.38-.72-1.03-.36-2.88.81-4.12 1.16-1.26 2.68-1.42 3.4-.39z'
      fill='url(#banner_svg__SVGID_65_)'
    />
    <linearGradient
      id='banner_svg__SVGID_66_'
      gradientUnits='userSpaceOnUse'
      x1={114.269}
      y1={115.554}
      x2={119.219}
      y2={115.554}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M118.85 113.3c.33.47.43 1.11.34 1.78-.07.55-.28 1.12-.61 1.65-.15.25-.33.48-.53.7-1.16 1.24-2.69 1.41-3.41.38-.72-1.03-.36-2.88.81-4.12 1.16-1.25 2.68-1.42 3.4-.39z'
      fill='url(#banner_svg__SVGID_66_)'
    />
    <linearGradient
      id='banner_svg__SVGID_67_'
      gradientUnits='userSpaceOnUse'
      x1={143.067}
      y1={132.321}
      x2={148.017}
      y2={132.321}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M147.65 130.07c.33.47.43 1.11.34 1.78-.07.55-.28 1.12-.61 1.65-.15.25-.33.48-.53.7-1.16 1.24-2.69 1.41-3.41.38-.72-1.03-.36-2.88.81-4.12 1.15-1.25 2.68-1.42 3.4-.39z'
      fill='url(#banner_svg__SVGID_67_)'
    />
    <linearGradient
      id='banner_svg__SVGID_68_'
      gradientUnits='userSpaceOnUse'
      x1={143.067}
      y1={171.434}
      x2={148.017}
      y2={171.434}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M147.65 169.18c.33.47.43 1.11.34 1.78-.07.55-.28 1.12-.61 1.65-.15.25-.33.48-.53.7-1.16 1.24-2.69 1.41-3.41.38-.72-1.03-.36-2.88.81-4.12 1.15-1.25 2.68-1.42 3.4-.39z'
      fill='url(#banner_svg__SVGID_68_)'
    />
    <linearGradient
      id='banner_svg__SVGID_69_'
      gradientUnits='userSpaceOnUse'
      x1={132.515}
      y1={79.983}
      x2={137.466}
      y2={79.983}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M137.1 77.73c.33.47.43 1.11.34 1.78-.07.55-.28 1.12-.61 1.65-.15.25-.33.48-.53.7-1.16 1.24-2.69 1.41-3.41.38-.72-1.03-.36-2.88.81-4.12 1.15-1.25 2.68-1.42 3.4-.39z'
      fill='url(#banner_svg__SVGID_69_)'
    />
    <linearGradient
      id='banner_svg__SVGID_70_'
      gradientUnits='userSpaceOnUse'
      x1={88.771}
      y1={172.336}
      x2={93.722}
      y2={172.336}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M93.35 170.09c.33.47.43 1.11.34 1.78-.07.55-.28 1.12-.61 1.65-.15.25-.33.48-.53.7-1.16 1.24-2.69 1.41-3.41.38-.72-1.03-.36-2.88.81-4.12 1.16-1.26 2.69-1.43 3.4-.39z'
      fill='url(#banner_svg__SVGID_70_)'
    />
    <linearGradient
      id='banner_svg__SVGID_71_'
      gradientUnits='userSpaceOnUse'
      x1={59.653}
      y1={180.741}
      x2={64.603}
      y2={180.741}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M64.23 178.49c.33.47.43 1.11.34 1.78-.07.55-.28 1.12-.61 1.65-.15.25-.33.48-.53.7-1.16 1.24-2.69 1.41-3.41.38-.72-1.03-.36-2.88.81-4.12 1.16-1.25 2.69-1.42 3.4-.39z'
      fill='url(#banner_svg__SVGID_71_)'
    />
    <linearGradient
      id='banner_svg__SVGID_72_'
      gradientUnits='userSpaceOnUse'
      x1={154.922}
      y1={117.881}
      x2={159.873}
      y2={117.881}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M159.5 115.63c.33.47.43 1.11.34 1.78-.07.55-.28 1.12-.61 1.65-.15.25-.33.48-.53.7-1.16 1.24-2.69 1.41-3.41.38-.72-1.03-.36-2.88.81-4.12 1.16-1.25 2.69-1.42 3.4-.39z'
      fill='url(#banner_svg__SVGID_72_)'
    />
    <linearGradient
      id='banner_svg__SVGID_73_'
      gradientUnits='userSpaceOnUse'
      x1={183.413}
      y1={101.072}
      x2={188.363}
      y2={101.072}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M187.99 98.82c.33.47.43 1.11.34 1.78-.07.55-.28 1.12-.61 1.65-.15.25-.33.48-.53.7-1.16 1.24-2.69 1.41-3.41.38-.72-1.03-.36-2.88.81-4.12 1.16-1.25 2.69-1.42 3.4-.39z'
      fill='url(#banner_svg__SVGID_73_)'
    />
    <linearGradient
      id='banner_svg__SVGID_74_'
      gradientUnits='userSpaceOnUse'
      x1={200.768}
      y1={125.898}
      x2={201.911}
      y2={125.898}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_74_)' d='M200.77 113.16h1.14v25.48h-1.14z' />
    <linearGradient
      id='banner_svg__SVGID_75_'
      gradientUnits='userSpaceOnUse'
      x1={198.943}
      y1={110.356}
      x2={203.893}
      y2={110.356}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M203.52 108.11c.33.47.43 1.11.34 1.78-.07.55-.28 1.12-.61 1.65-.15.25-.33.48-.53.7-1.16 1.24-2.69 1.41-3.41.38-.72-1.03-.36-2.88.81-4.12 1.16-1.26 2.69-1.43 3.4-.39z'
      fill='url(#banner_svg__SVGID_75_)'
    />
    <linearGradient
      id='banner_svg__SVGID_76_'
      gradientUnits='userSpaceOnUse'
      x1={198.943}
      y1={140.902}
      x2={203.893}
      y2={140.902}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M203.52 138.65c.33.47.43 1.11.34 1.78-.07.55-.28 1.12-.61 1.65-.15.25-.33.48-.53.7-1.16 1.24-2.69 1.41-3.41.38-.72-1.03-.36-2.88.81-4.12 1.16-1.25 2.69-1.42 3.4-.39z'
      fill='url(#banner_svg__SVGID_76_)'
    />
    <linearGradient
      id='banner_svg__SVGID_77_'
      gradientUnits='userSpaceOnUse'
      x1={11.069}
      y1={152.399}
      x2={24.045}
      y2={152.399}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M24.04 144.03v9.46c0 .42-.22.81-.59 1.02l-11.22 6.48a.782.782 0 01-1.17-.68v-9.01-.09c2.16.94 6.48 2.93 6.48 2.93l6.4-10.44c.07.1.1.21.1.33z'
      fill='url(#banner_svg__SVGID_77_)'
    />
    <linearGradient
      id='banner_svg__SVGID_78_'
      gradientUnits='userSpaceOnUse'
      x1={11.074}
      y1={148.767}
      x2={23.956}
      y2={148.767}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M23.96 143.7l-6.4 10.44s-4.32-1.99-6.48-2.93c.03-.5.31-.96.75-1.22l11.27-6.51c.32-.18.69-.06.86.22z'
      fill='url(#banner_svg__SVGID_78_)'
    />
    <linearGradient
      id='banner_svg__SVGID_79_'
      gradientUnits='userSpaceOnUse'
      x1={39.285}
      y1={81.928}
      x2={41.603}
      y2={81.928}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M41.43 80.87c.15.22.2.52.16.83-.03.26-.13.53-.28.77-.07.12-.15.22-.25.33-.55.58-1.26.66-1.6.18-.34-.48-.17-1.35.38-1.93.54-.58 1.25-.66 1.59-.18z'
      fill='url(#banner_svg__SVGID_79_)'
    />
    <linearGradient
      id='banner_svg__SVGID_80_'
      gradientUnits='userSpaceOnUse'
      x1={44.565}
      y1={78.889}
      x2={46.884}
      y2={78.889}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M46.71 77.83c.15.22.2.52.16.83-.03.26-.13.53-.28.77-.07.12-.15.22-.25.33-.55.58-1.26.66-1.6.18-.34-.48-.17-1.35.38-1.93.54-.58 1.26-.66 1.59-.18z'
      fill='url(#banner_svg__SVGID_80_)'
    />
    <linearGradient
      id='banner_svg__SVGID_81_'
      gradientUnits='userSpaceOnUse'
      x1={49.209}
      y1={76.147}
      x2={51.528}
      y2={76.147}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M51.36 75.09c.15.22.2.52.16.83-.03.26-.13.53-.28.77-.07.12-.15.22-.25.33-.55.58-1.26.66-1.6.18-.34-.48-.17-1.35.38-1.93.53-.58 1.25-.66 1.59-.18z'
      fill='url(#banner_svg__SVGID_81_)'
    />
    <linearGradient
      id='banner_svg__SVGID_82_'
      gradientUnits='userSpaceOnUse'
      x1={110.379}
      y1={24.607}
      x2={123.713}
      y2={24.607}
      gradientTransform='rotate(7.048 9.64 -107.524)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M93.39 40.44a.53.53 0 01-.4-.79c.11-.2 2.7-4.85 7.23-6.52 3.55-1.31 6.46.72 6.58.81.24.17.3.51.13.75-.17.24-.51.3-.75.13-.02-.02-2.58-1.78-5.59-.68-4.16 1.53-6.64 5.98-6.66 6.02a.54.54 0 01-.54.28z'
      fill='url(#banner_svg__SVGID_82_)'
    />
    <linearGradient
      id='banner_svg__SVGID_83_'
      gradientUnits='userSpaceOnUse'
      x1={112.399}
      y1={26.877}
      x2={122.19}
      y2={26.877}
      gradientTransform='rotate(7.048 9.64 -107.524)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M95.25 41.82a.53.53 0 01-.4-.79c.08-.14 1.95-3.5 5.23-4.7 2.6-.96 4.74.53 4.83.6.24.17.3.51.13.75-.17.24-.51.3-.75.13-.07-.05-1.77-1.22-3.83-.46-2.9 1.07-4.64 4.18-4.65 4.21a.6.6 0 01-.56.26z'
      fill='url(#banner_svg__SVGID_83_)'
    />
    <linearGradient
      id='banner_svg__SVGID_84_'
      gradientUnits='userSpaceOnUse'
      x1={114.715}
      y1={29.375}
      x2={121.266}
      y2={29.375}
      gradientTransform='rotate(7.048 9.64 -107.524)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M97.37 43.56a.53.53 0 01-.4-.79c.05-.09 1.26-2.26 3.39-3.05 1.73-.64 3.16.36 3.22.4.24.17.3.51.13.75-.17.24-.51.3-.75.13-.04-.03-1.03-.7-2.22-.27-1.76.65-2.81 2.54-2.82 2.55-.12.2-.34.31-.55.28z'
      fill='url(#banner_svg__SVGID_84_)'
    />
    <linearGradient
      id='banner_svg__SVGID_85_'
      gradientUnits='userSpaceOnUse'
      x1={99.759}
      y1={44.187}
      x2={101.844}
      y2={44.187}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M101.69 43.24c.14.2.18.47.14.75-.03.23-.12.47-.26.69-.06.1-.14.2-.22.29-.49.52-1.13.59-1.44.16-.3-.44-.15-1.21.34-1.74.49-.51 1.14-.59 1.44-.15z'
      fill='url(#banner_svg__SVGID_85_)'
    />
    <linearGradient
      id='banner_svg__SVGID_86_'
      gradientUnits='userSpaceOnUse'
      x1={156.197}
      y1={55.333}
      x2={162.535}
      y2={55.333}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_86_)' d='M156.2 55.33l3.17 1.83 3.16-1.83-3.16-1.83z' />
    <linearGradient
      id='banner_svg__SVGID_87_'
      gradientUnits='userSpaceOnUse'
      x1={156.197}
      y1={56.423}
      x2={159.368}
      y2={56.423}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_87_)' d='M156.2 55.33v.35l3.17 1.83v-.35z' />
    <linearGradient
      id='banner_svg__SVGID_88_'
      gradientUnits='userSpaceOnUse'
      x1={159.368}
      y1={56.424}
      x2={162.535}
      y2={56.424}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_88_)' d='M159.37 57.16v.35l3.16-1.82v-.36z' />
    <linearGradient
      id='banner_svg__SVGID_89_'
      gradientUnits='userSpaceOnUse'
      x1={153.585}
      y1={49.181}
      x2={165.576}
      y2={49.181}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_89_)'
      d='M165.58 50.33l-5 2.89-.45.25-1.55.9-.44.26-4.56 2.62.01-9.22 11.98-6.92z'
    />
    <linearGradient
      id='banner_svg__SVGID_90_'
      gradientUnits='userSpaceOnUse'
      x1={152.696}
      y1={52.418}
      x2={153.588}
      y2={52.418}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_90_)' d='M153.58 57.25l-.88-.44v-9.23l.89.45z' />
    <linearGradient
      id='banner_svg__SVGID_91_'
      gradientUnits='userSpaceOnUse'
      x1={152.699}
      y1={44.347}
      x2={165.573}
      y2={44.347}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_91_)' d='M152.7 47.58l11.98-6.91.89.44-11.98 6.92z' />
    <linearGradient
      id='banner_svg__SVGID_92_'
      gradientUnits='userSpaceOnUse'
      x1={158.582}
      y1={54.772}
      x2={160.579}
      y2={54.772}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_92_)' d='M160.58 53.22v1.96l-2 1.15v-1.96l1.55-.9z' />
    <linearGradient
      id='banner_svg__SVGID_93_'
      gradientUnits='userSpaceOnUse'
      x1={158.138}
      y1={55.349}
      x2={158.582}
      y2={55.349}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_93_)' d='M158.58 54.37v1.96l-.44-.24v-1.46z' />
    <linearGradient
      id='banner_svg__SVGID_94_'
      gradientUnits='userSpaceOnUse'
      x1={154.27}
      y1={49.181}
      x2={164.891}
      y2={49.181}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_94_)'
      d='M164.89 50.2l-4.43 2.56-.39.22-1.37.8-.4.22-4.03 2.33v-8.17l10.62-6.13z'
    />
    <linearGradient
      id='banner_svg__SVGID_95_'
      gradientUnits='userSpaceOnUse'
      x1={207.054}
      y1={61.989}
      x2={220.703}
      y2={61.989}
    >
      <stop offset={0} stopColor='#94c2ff' />
      <stop offset={1} stopColor='#e9f4ff' />
    </linearGradient>
    <path
      d='M207.12 66.95c.03.09.06.18.11.26 0 .01.01.01.01.02a1 1 0 00.26.31l.07.05c.32.2.74.19 1.17-.04l.09-.05 3.6-2.09.49-.28 4.04-2.34.23-.13 2.11-1.22h.01c.2-.11.39-.27.58-.47.13-.14.25-.3.35-.46.22-.35.36-.73.42-1.11.08-.46.04-.89-.15-1.22a.911.911 0 00-.34-.36.963.963 0 00-.37-.13c-.17-.02-.35 0-.54.07-.03.01-.05.02-.08.03-.21.08-.41.22-.61.4-.18-.24-.4-.4-.66-.47-.6-.17-1.36.09-2.02.78-.06-.32-.16-.62-.29-.89a.638.638 0 00-.08-.14c-.22-.4-.51-.7-.85-.9-.08-.05-.16-.09-.24-.12a2.43 2.43 0 00-.6-.17c-1.04-.14-2.29.39-3.38 1.57-1.6 1.73-2.31 4.26-1.8 6.04-.27.1-.54.29-.79.57-.04.05-.09.1-.13.15-.56.69-.81 1.65-.61 2.34z'
      fill='url(#banner_svg__SVGID_95_)'
    />
    <linearGradient
      id='banner_svg__SVGID_96_'
      gradientUnits='userSpaceOnUse'
      x1={211.427}
      y1={63.86}
      x2={216.222}
      y2={63.86}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_96_)' d='M212.7 61.49v2.71l-1.27.73 2.44 2.66 2.35-5.42-1.17.67v-2.71z' />
    <linearGradient
      id='banner_svg__SVGID_97_'
      gradientUnits='userSpaceOnUse'
      x1={248.744}
      y1={88.36}
      x2={260.863}
      y2={88.36}
    >
      <stop offset={0} stopColor='#94c2ff' />
      <stop offset={1} stopColor='#e9f4ff' />
    </linearGradient>
    <path
      d='M260.78 87.21c-.03.2-.06.41-.11.61-.24 1.15-.7 2.33-1.38 3.42-.37.6-.81 1.18-1.31 1.7-2.46 2.63-5.57 3.29-7.51 1.8-.31-.24-.59-.53-.83-.88-.72-1.04-1-2.42-.88-3.9.05-.6.17-1.22.35-1.84.45-1.54 1.3-3.08 2.5-4.36.7-.75 1.46-1.34 2.23-1.76 1.8-1 3.66-1.1 5.03-.22.41.26.78.61 1.08 1.05.81 1.17 1.06 2.73.83 4.38z'
      fill='url(#banner_svg__SVGID_97_)'
    />
    <linearGradient
      id='banner_svg__SVGID_98_'
      gradientUnits='userSpaceOnUse'
      x1={249.122}
      y1={85.234}
      x2={255.76}
      y2={85.234}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M255.47 84.72c-1.19.2-3.16 1.29-2.68 1.82s-.43 1.39-1.43 1.82c-.52.22-1.45.02-2.23-.22.45-1.54 1.3-3.08 2.5-4.36.7-.75 1.46-1.34 2.23-1.76.13.12 2.76 2.51 1.61 2.7z'
      fill='url(#banner_svg__SVGID_98_)'
    />
    <linearGradient
      id='banner_svg__SVGID_99_'
      gradientUnits='userSpaceOnUse'
      x1={252.95}
      y1={90.098}
      x2={258.191}
      y2={90.098}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M257.09 86.94c-1.23-.15-2.46.34-3.29 1.26-.27.3-.43.57-.29.71.39.39.39 2.04-.22 2.47-.61.43-.34 1.4.32 1.86.66.47 2.12-3.12 2.62-3.1.5.02 2.4-1.92 1.86-2.74-.17-.28-.56-.41-1-.46z'
      fill='url(#banner_svg__SVGID_99_)'
    />
    <linearGradient
      id='banner_svg__SVGID_100_'
      gradientUnits='userSpaceOnUse'
      x1={258.027}
      y1={84.806}
      x2={260.863}
      y2={84.806}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M260.78 87.21c-.03.2-.06.41-.11.61-.99-.32-1.23-1.1-1.1-2.04.14-1-.62-1.43-1.38-1.86-.46-.26.13-1.31.68-2.11.41.26.78.61 1.08 1.05.81 1.14 1.06 2.7.83 4.35z'
      fill='url(#banner_svg__SVGID_100_)'
    />
    <linearGradient
      id='banner_svg__SVGID_101_'
      gradientUnits='userSpaceOnUse'
      x1={248.744}
      y1={92.355}
      x2={251.32}
      y2={92.355}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M251.32 93.28c.02.24-.41.88-.84 1.46-.31-.24-.59-.53-.83-.88-.72-1.04-1-2.42-.88-3.9.56 0 1.12.1 1.36.44.5.72-.42 1.33 0 1.79.44.48 1.15.64 1.19 1.09z'
      fill='url(#banner_svg__SVGID_101_)'
    />
    <linearGradient
      id='banner_svg__SVGID_102_'
      gradientUnits='userSpaceOnUse'
      x1={105.844}
      y1={209.471}
      x2={108.946}
      y2={209.471}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M108.37 210.63c-.1 0-.2-.03-.29-.08l-1.96-1.18a.57.57 0 01.58-.98l1.96 1.18c.27.16.36.51.2.78-.1.18-.29.28-.49.28z'
      fill='url(#banner_svg__SVGID_102_)'
    />
    <linearGradient
      id='banner_svg__SVGID_103_'
      gradientUnits='userSpaceOnUse'
      x1={111.758}
      y1={219.344}
      x2={151.125}
      y2={219.344}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M150.55 226.82a.58.58 0 01-.33-.1l-3.78-2.65a.575.575 0 01.66-.94l3.78 2.65c.26.18.32.54.14.8-.11.16-.29.24-.47.24zm-7.56-5.27c-.03 0-.06 0-.09-.01l-4.55-.75a.57.57 0 01-.47-.66.57.57 0 01.66-.47l4.55.75c.31.05.52.34.47.66-.05.28-.3.48-.57.48zm-9.11-1.49c-.03 0-.06 0-.09-.01l-4.55-.75a.57.57 0 01-.47-.66.57.57 0 01.66-.47l4.55.75c.31.05.52.34.47.66-.05.28-.29.48-.57.48zm-9.11-1.49c-.03 0-.06 0-.09-.01l-4.5-.74-.24-.14a.57.57 0 01.58-.98l.05.03 4.28.7c.31.05.52.34.47.66-.03.28-.27.48-.55.48zm-8.49-3.18c-.1 0-.2-.03-.29-.08l-3.96-2.38a.57.57 0 01.58-.98l3.96 2.38c.27.16.36.51.2.78-.1.18-.29.28-.49.28z'
      fill='url(#banner_svg__SVGID_103_)'
    />
    <linearGradient
      id='banner_svg__SVGID_104_'
      gradientUnits='userSpaceOnUse'
      x1={153.757}
      y1={229.561}
      x2={156.771}
      y2={229.561}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M156.2 230.79a.58.58 0 01-.33-.1l-1.87-1.31a.575.575 0 01.66-.94l1.87 1.31c.26.18.32.54.14.8-.11.15-.29.24-.47.24z'
      fill='url(#banner_svg__SVGID_104_)'
    />
    <linearGradient
      id='banner_svg__SVGID_105_'
      gradientUnits='userSpaceOnUse'
      x1={87.072}
      y1={256.102}
      x2={88.221}
      y2={256.102}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M87.65 257.82a.57.57 0 01-.57-.57l-.01-2.29c0-.32.25-.57.57-.57.32 0 .57.26.57.57l.01 2.29c0 .31-.25.57-.57.57z'
      fill='url(#banner_svg__SVGID_105_)'
    />
    <linearGradient
      id='banner_svg__SVGID_106_'
      gradientUnits='userSpaceOnUse'
      x1={87.053}
      y1={249.393}
      x2={90.164}
      y2={249.393}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M87.63 251.69a.57.57 0 01-.57-.57l-.01-2.61 2.25-1.33a.57.57 0 01.58.98l-1.69 1 .01 1.96c0 .31-.25.57-.57.57z'
      fill='url(#banner_svg__SVGID_106_)'
    />
    <linearGradient
      id='banner_svg__SVGID_107_'
      gradientUnits='userSpaceOnUse'
      x1={92.5}
      y1={244.576}
      x2={97.123}
      y2={244.576}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M93.07 246.18c-.2 0-.39-.1-.49-.28a.57.57 0 01.2-.78l3.48-2.06a.57.57 0 01.58.98l-3.48 2.06c-.09.05-.19.08-.29.08z'
      fill='url(#banner_svg__SVGID_107_)'
    />
    <linearGradient
      id='banner_svg__SVGID_108_'
      gradientUnits='userSpaceOnUse'
      x1={99.459}
      y1={240.856}
      x2={104.549}
      y2={240.856}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M100.03 242.06c-.2 0-.39-.1-.49-.28a.57.57 0 01.2-.78l2.25-1.34 2.27 1.31c.27.16.37.51.21.78s-.51.37-.78.21l-1.69-.98-1.68.99c-.09.06-.19.09-.29.09z'
      fill='url(#banner_svg__SVGID_108_)'
    />
    <linearGradient
      id='banner_svg__SVGID_109_'
      gradientUnits='userSpaceOnUse'
      x1={106.913}
      y1={244.058}
      x2={110.036}
      y2={244.058}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M109.46 245.2c-.1 0-.2-.02-.29-.08l-1.98-1.14a.573.573 0 01-.21-.78c.16-.27.51-.37.78-.21l1.98 1.14c.27.16.37.51.21.78-.1.19-.29.29-.49.29z'
      fill='url(#banner_svg__SVGID_109_)'
    />
    <linearGradient
      id='banner_svg__SVGID_110_'
      gradientUnits='userSpaceOnUse'
      x1={66.107}
      y1={267.531}
      x2={72.299}
      y2={267.531}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M72.3 267.53c0 1.16-1.38 2.1-3.09 2.1s-3.1-.94-3.1-2.1 1.39-2.1 3.1-2.1c1.71 0 3.09.94 3.09 2.1z'
      fill='url(#banner_svg__SVGID_110_)'
    />
    <linearGradient
      id='banner_svg__SVGID_111_'
      gradientUnits='userSpaceOnUse'
      x1={84.53}
      y1={260.876}
      x2={90.721}
      y2={260.876}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M90.72 260.88c0 1.16-1.38 2.1-3.09 2.1s-3.1-.94-3.1-2.1c0-1.16 1.39-2.1 3.1-2.1 1.71-.01 3.09.94 3.09 2.1z'
      fill='url(#banner_svg__SVGID_111_)'
    />
    <linearGradient
      id='banner_svg__SVGID_112_'
      gradientUnits='userSpaceOnUse'
      x1={109.182}
      y1={247.271}
      x2={115.373}
      y2={247.271}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M115.37 247.27c0 1.16-1.38 2.1-3.09 2.1s-3.1-.94-3.1-2.1c0-1.16 1.39-2.1 3.1-2.1 1.71 0 3.09.94 3.09 2.1z'
      fill='url(#banner_svg__SVGID_112_)'
    />
    <linearGradient
      id='banner_svg__SVGID_113_'
      gradientUnits='userSpaceOnUse'
      x1={133.466}
      y1={245.097}
      x2={139.657}
      y2={245.097}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M139.66 245.1c0 1.16-1.38 2.1-3.09 2.1s-3.1-.94-3.1-2.1c0-1.16 1.39-2.1 3.1-2.1 1.7 0 3.09.94 3.09 2.1z'
      fill='url(#banner_svg__SVGID_113_)'
    />
    <linearGradient
      id='banner_svg__SVGID_114_'
      gradientUnits='userSpaceOnUse'
      x1={156.627}
      y1={232.361}
      x2={162.818}
      y2={232.361}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M162.82 232.36c0 1.16-1.38 2.1-3.09 2.1s-3.1-.94-3.1-2.1c0-1.16 1.39-2.1 3.1-2.1 1.7 0 3.09.94 3.09 2.1z'
      fill='url(#banner_svg__SVGID_114_)'
    />
    <linearGradient
      id='banner_svg__SVGID_115_'
      gradientUnits='userSpaceOnUse'
      x1={177.717}
      y1={211.894}
      x2={183.908}
      y2={211.894}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M183.91 211.89c0 1.16-1.38 2.1-3.09 2.1s-3.1-.94-3.1-2.1c0-1.16 1.39-2.1 3.1-2.1s3.09.94 3.09 2.1z'
      fill='url(#banner_svg__SVGID_115_)'
    />
    <linearGradient
      id='banner_svg__SVGID_116_'
      gradientUnits='userSpaceOnUse'
      x1={177.717}
      y1={195.4}
      x2={183.908}
      y2={195.4}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M183.91 195.4c0 1.16-1.38 2.1-3.09 2.1s-3.1-.94-3.1-2.1c0-1.16 1.39-2.1 3.1-2.1s3.09.94 3.09 2.1z'
      fill='url(#banner_svg__SVGID_116_)'
    />
    <linearGradient
      id='banner_svg__SVGID_117_'
      gradientUnits='userSpaceOnUse'
      x1={199.667}
      y1={197.396}
      x2={205.858}
      y2={197.396}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M205.86 197.4c0 1.16-1.38 2.1-3.09 2.1s-3.1-.94-3.1-2.1c0-1.16 1.39-2.1 3.1-2.1s3.09.94 3.09 2.1z'
      fill='url(#banner_svg__SVGID_117_)'
    />
    <linearGradient
      id='banner_svg__SVGID_118_'
      gradientUnits='userSpaceOnUse'
      x1={235.868}
      y1={184.954}
      x2={242.06}
      y2={184.954}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M242.06 184.95c0 1.16-1.38 2.1-3.09 2.1s-3.1-.94-3.1-2.1c0-1.16 1.39-2.1 3.1-2.1s3.09.95 3.09 2.1z'
      fill='url(#banner_svg__SVGID_118_)'
    />
    <linearGradient
      id='banner_svg__SVGID_119_'
      gradientUnits='userSpaceOnUse'
      x1={260.863}
      y1={152.95}
      x2={267.055}
      y2={152.95}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M267.05 152.95c0 1.16-1.38 2.1-3.09 2.1s-3.1-.94-3.1-2.1c0-1.16 1.39-2.1 3.1-2.1 1.71 0 3.09.94 3.09 2.1z'
      fill='url(#banner_svg__SVGID_119_)'
    />
    <linearGradient
      id='banner_svg__SVGID_120_'
      gradientUnits='userSpaceOnUse'
      x1={-380.495}
      y1={190.022}
      x2={-373.122}
      y2={190.022}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#e9f4ff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M300.4 176.5l1.16 22.46.43 8.34 5.14.38c.25-2.25.49-4.13.61-4.45.12-.31-.18-1.82-.28-5.5-.02-.76-.03-1.61-.03-2.56.01-7.39-.5-16.86-1.91-19.18-.35-.57-.81-1.11-1.3-1.59h-.01c-.19-.19-2.46-1.88-2.65-2.05l-1.16 4.15z'
      fill='url(#banner_svg__SVGID_120_)'
    />
    <linearGradient
      id='banner_svg__SVGID_121_'
      gradientUnits='userSpaceOnUse'
      x1={-373.122}
      y1={190.867}
      x2={-362.029}
      y2={190.867}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#e9f4ff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M289.3 207.42l2.64 3.22 7.57-3.93.21-7.26.67-22.95-.89-2.97-2.22-2.45s-4.15.46-6.21 3.77c-2.05 3.31-.46 19.38-1.58 30.25-.08.83-.14 1.6-.19 2.32z'
      fill='url(#banner_svg__SVGID_121_)'
    />
    <path
      className='banner_svg__st126'
      d='M300.4 176.5s-1.73-.08-2.38.93c-.65 1.01-.77 1.72-.77 1.72s-.73-7.44.04-8.07l2.22 2.44.89 2.98z'
    />
    <path
      className='banner_svg__st126'
      d='M300.4 176.5s1.2-.22 1.91.3c.72.52.97 1.19.97 1.19s-.53-5.46-1.71-5.63l-.65 2.05-.52 2.09z'
    />
    <linearGradient
      id='banner_svg__SVGID_122_'
      gradientUnits='userSpaceOnUse'
      x1={-374.451}
      y1={172.531}
      x2={-370.015}
      y2={172.531}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#ffd4d5' />
      <stop offset={1} stopColor='#ff7f83' />
    </linearGradient>
    <path
      d='M297.63 168.56l-.34 2.53s.01 2.71 1.18 3.94 1.93 1.47 1.93 1.47 1.56-.95 1.3-2.98c-.27-2.03-.19-3.13-.19-3.13l-1.15-.74-2.73-1.09z'
      fill='url(#banner_svg__SVGID_122_)'
    />
    <linearGradient
      id='banner_svg__SVGID_123_'
      gradientUnits='userSpaceOnUse'
      x1={-379.459}
      y1={166.399}
      x2={-369.972}
      y2={166.399}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#ffd4d5' />
      <stop offset={1} stopColor='#ff7f83' />
    </linearGradient>
    <path
      d='M306.74 164.27s-.11 3.96-2.43 6.37c-2.32 2.41-3.48 1.91-3.48 1.91s-3.28-2.48-3.56-5.43c-.28-2.95 3.57-6.93 3.57-6.93l3.61.13 2.29 3.95z'
      fill='url(#banner_svg__SVGID_123_)'
    />
    <path
      className='banner_svg__st129'
      d='M307.91 158.07c.71.85 1.18 1.89 1.25 2.99.09 1.51-.57 2.35-2.42 3.21-2.47 1.14-4.77-.84-5.28.22-.51 1.06-.89 2.11-.89 2.11s-.47-2.48-1.39-1.88c-.92.61-.25 3.37-1.55 3.83-1.29.46-3.31-5.94-.82-8.82 2.48-2.87 4.89-1.63 6.21-1.47 1.32.16 1.09-2.3 2.63-1.94.8.21 1.64 1.02 2.26 1.75z'
    />
    <linearGradient
      id='banner_svg__SVGID_124_'
      gradientUnits='userSpaceOnUse'
      x1={-383.12}
      y1={186.073}
      x2={-376.954}
      y2={186.073}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M304.23 174.41c.5.49.95 1.02 1.3 1.59 1.41 2.32 1.92 11.79 1.91 19.18 0 .95.01 1.8.03 2.56H309s2.41-2.26.9-5.33c-1.51-3.07-.95-8.45-1.43-12.5-.46-3.94-4.02-5.41-4.24-5.5z'
      fill='url(#banner_svg__SVGID_124_)'
    />
    <linearGradient
      id='banner_svg__SVGID_125_'
      gradientUnits='userSpaceOnUse'
      x1={-374.285}
      y1={187.98}
      x2={-372.449}
      y2={187.98}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path d='M299.73 199.46l1.68-.23s.06-.1.16-.26l-1.16-22.46-.68 22.95z' fill='url(#banner_svg__SVGID_125_)' />
    <path className='banner_svg__st17' d='M360.73 186.92l-43.75 25.25-21.87-12.63 43.75-25.25z' />
    <path className='banner_svg__st126' d='M295.11 199.54l-14.58 8.43v41.64l36.46 21.5-.01-58.94z' />
    <linearGradient
      id='banner_svg__SVGID_126_'
      gradientUnits='userSpaceOnUse'
      x1={-433.458}
      y1={229.012}
      x2={-389.709}
      y2={229.012}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_126_)' d='M360.73 186.92v58.93l-43.74 25.26-.01-58.94z' />
    <path className='banner_svg__st17' d='M324.28 250.06v8.42l7.29-4.21v-8.42z' />
    <linearGradient
      id='banner_svg__SVGID_127_'
      gradientUnits='userSpaceOnUse'
      x1={-425.36}
      y1={173}
      x2={-402.962}
      y2={173}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_127_)' d='M330.24 169.77v19.4l22.4-12.94v-19.4z' />
    <linearGradient
      id='banner_svg__SVGID_128_'
      gradientUnits='userSpaceOnUse'
      x1={-402.962}
      y1={179.155}
      x2={-401.716}
      y2={179.155}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_128_)' d='M330.24 189.17l-1.25-.63v-19.4l1.25.63z' />
    <linearGradient
      id='banner_svg__SVGID_129_'
      gradientUnits='userSpaceOnUse'
      x1={-424.305}
      y1={187.343}
      x2={-409.616}
      y2={187.343}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_129_)' d='M336.89 187.34l7.35 4.24 7.34-4.24-7.34-4.24z' />
    <path className='banner_svg__st17' d='M328.99 169.14l22.4-12.93 1.25.62-22.4 12.94z' />
    <linearGradient
      id='banner_svg__SVGID_130_'
      gradientUnits='userSpaceOnUse'
      x1={-419.768}
      y1={178.658}
      x2={-411.36}
      y2={178.658}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_130_)' d='M338.64 171.38l5.6-3.23 2.8 11.32v6.46l-5.6 3.23-.03-6.74z' />
    <path className='banner_svg__st17' d='M341.44 189.16l-.63-.38-.03-6.75.63.39z' />
    <path
      className='banner_svg__st17'
      d='M340.78 182.03L338.01 171l.63.38 2.77 11.04zM338.01 171l5.6-3.24.63.39-5.6 3.23z'
    />
    <linearGradient
      id='banner_svg__SVGID_131_'
      gradientUnits='userSpaceOnUse'
      x1={-416.96}
      y1={189.872}
      x2={-409.616}
      y2={189.872}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_131_)' d='M336.89 187.34v.82l7.35 4.24v-.82z' />
    <linearGradient
      id='banner_svg__SVGID_132_'
      gradientUnits='userSpaceOnUse'
      x1={-424.305}
      y1={189.872}
      x2={-416.96}
      y2={189.872}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_132_)' d='M351.58 187.34v.82l-7.34 4.24v-.82z' />
    <linearGradient
      id='banner_svg__SVGID_133_'
      gradientUnits='userSpaceOnUse'
      x1={-405.172}
      y1={198.292}
      x2={-376.008}
      y2={198.292}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_133_)' d='M303.28 198.29l14.59 8.42 14.58-8.42-14.58-8.42z' />
    <linearGradient
      id='banner_svg__SVGID_134_'
      gradientUnits='userSpaceOnUse'
      x1={-382.422}
      y1={245.852}
      x2={-367.834}
      y2={245.852}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_134_)' d='M295.11 233.23v16.83l14.58 8.42.01-16.84z' />
    <linearGradient
      id='banner_svg__SVGID_135_'
      gradientUnits='userSpaceOnUse'
      x1={-392.66}
      y1={193.746}
      x2={-388.441}
      y2={193.746}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M319.94 193.75c0 .79-.94 1.43-2.11 1.43s-2.11-.64-2.11-1.43c0-.79.95-1.43 2.11-1.43 1.16-.01 2.11.64 2.11 1.43z'
      fill='url(#banner_svg__SVGID_135_)'
    />
    <linearGradient
      id='banner_svg__SVGID_136_'
      gradientUnits='userSpaceOnUse'
      x1={-385.94}
      y1={197.732}
      x2={-381.72}
      y2={197.732}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M313.22 197.73c0 .79-.94 1.43-2.11 1.43s-2.11-.64-2.11-1.43.95-1.43 2.11-1.43 2.11.64 2.11 1.43z'
      fill='url(#banner_svg__SVGID_136_)'
    />
    <linearGradient
      id='banner_svg__SVGID_137_'
      gradientUnits='userSpaceOnUse'
      x1={-402.354}
      y1={252.166}
      x2={-399.446}
      y2={252.166}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M329.41 250.84c.19.28.25.65.2 1.05a2.5 2.5 0 01-.36.97c-.09.14-.19.28-.31.41-.68.73-1.58.83-2 .22-.42-.61-.21-1.69.47-2.42.69-.73 1.58-.83 2-.23z'
      fill='url(#banner_svg__SVGID_137_)'
    />
    <path
      className='banner_svg__st126'
      d='M333.9 269.38v-14.53l-5.41-2.64.34-.69 5.84 2.85v13.67l15.11-8.72.01-70.93-12.52-7.22.39-.66 12.9 7.43-.02 71.82z'
    />
    <linearGradient
      id='banner_svg__SVGID_138_'
      gradientUnits='userSpaceOnUse'
      x1={-370.91}
      y1={186.302}
      x2={-357.633}
      y2={186.302}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M294.42 199.07l.65-.55 2.66-2.26.46-.39s-5.6-6.01-7.16-8.45c-1.56-2.45 2.1-3.73 3.95-8.58 1.85-4.84-.99-5.23-.99-5.23s-1.9-.62-3.75 1.66c-1.31 1.62-3.27 4.17-5.11 9.12-1.86 4.96 9.29 14.68 9.29 14.68z'
      fill='url(#banner_svg__SVGID_138_)'
    />
    <linearGradient
      id='banner_svg__SVGID_139_'
      gradientUnits='userSpaceOnUse'
      x1={-374.219}
      y1={198.901}
      x2={-367.797}
      y2={198.901}
      gradientTransform='matrix(-1 0 0 1 -72.724 0)'
    >
      <stop offset={0} stopColor='#ffd4d5' />
      <stop offset={1} stopColor='#ff7f83' />
    </linearGradient>
    <path
      d='M295.07 198.52c.19.15.44.31.79.48 1.15.55 1.75.68 2.2 1.25.45.58.7 1.3.7 1.3s3.33-1.02 2.65-2.32c-.54-1.04-2.24-2.45-3.68-2.97l-2.66 2.26z'
      fill='url(#banner_svg__SVGID_139_)'
    />
    <path className='banner_svg__st17' d='M285.71 268.12l-64.23 37.08-64.22-37.09 63.68-36.77.54-.31z' />
    <linearGradient
      id='banner_svg__SVGID_140_'
      gradientUnits='userSpaceOnUse'
      x1={157.257}
      y1={297.242}
      x2={221.485}
      y2={297.242}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_140_)' d='M157.26 268.11v21.18l64.22 37.09V305.2z' />
    <linearGradient
      id='banner_svg__SVGID_141_'
      gradientUnits='userSpaceOnUse'
      x1={221.485}
      y1={297.248}
      x2={285.712}
      y2={297.248}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_141_)' d='M221.48 326.38l64.23-37.08v-21.18l-64.23 37.08z' />
    <linearGradient
      id='banner_svg__SVGID_142_'
      gradientUnits='userSpaceOnUse'
      x1={200.405}
      y1={304.198}
      x2={203.88}
      y2={304.198}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M200.66 302.62c-.23.33-.3.78-.24 1.25.05.39.2.79.43 1.16.11.17.23.34.37.49.82.87 1.89.99 2.39.26.5-.73.25-2.02-.57-2.89-.8-.88-1.87-1-2.38-.27z'
      fill='url(#banner_svg__SVGID_142_)'
    />
    <linearGradient
      id='banner_svg__SVGID_143_'
      gradientUnits='userSpaceOnUse'
      x1={205.984}
      y1={307.564}
      x2={209.459}
      y2={307.564}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M206.24 305.98c-.23.33-.3.78-.24 1.25.05.39.2.79.43 1.16.11.17.23.34.37.49.82.87 1.89.99 2.39.26.5-.73.25-2.02-.57-2.89-.8-.87-1.87-.99-2.38-.27z'
      fill='url(#banner_svg__SVGID_143_)'
    />
    <linearGradient
      id='banner_svg__SVGID_144_'
      gradientUnits='userSpaceOnUse'
      x1={211.563}
      y1={310.814}
      x2={215.037}
      y2={310.814}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M211.82 309.23c-.23.33-.3.78-.24 1.25.05.39.2.79.43 1.16.11.17.23.34.37.49.82.87 1.89.99 2.39.26.5-.73.25-2.02-.57-2.89-.8-.87-1.87-.99-2.38-.27z'
      fill='url(#banner_svg__SVGID_144_)'
    />
    <linearGradient
      id='banner_svg__SVGID_145_'
      gradientUnits='userSpaceOnUse'
      x1={167.386}
      y1={286.755}
      x2={174.667}
      y2={286.755}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M167.39 283.85v1.83c0 .23.12.44.32.55l6.47 3.74c.22.13.49-.03.49-.28v-1.8c0-.27-.15-.52-.38-.66l-6.37-3.68a.35.35 0 00-.53.3z'
      fill='url(#banner_svg__SVGID_145_)'
    />
    <linearGradient
      id='banner_svg__SVGID_146_'
      gradientUnits='userSpaceOnUse'
      x1={276.537}
      y1={282.67}
      x2={280.012}
      y2={282.67}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M279.75 281.09c.23.33.3.78.24 1.25-.05.39-.2.79-.43 1.16-.11.17-.23.34-.37.49-.82.87-1.89.99-2.39.26-.5-.73-.25-2.02.57-2.89.81-.88 1.88-1 2.38-.27z'
      fill='url(#banner_svg__SVGID_146_)'
    />
    <linearGradient
      id='banner_svg__SVGID_147_'
      gradientUnits='userSpaceOnUse'
      x1={270.958}
      y1={286.035}
      x2={274.433}
      y2={286.035}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M274.17 284.46c.23.33.3.78.24 1.25-.05.39-.2.79-.43 1.16-.11.17-.23.34-.37.49-.82.87-1.89.99-2.39.26-.5-.73-.25-2.02.57-2.89.81-.88 1.88-1 2.38-.27z'
      fill='url(#banner_svg__SVGID_147_)'
    />
    <linearGradient
      id='banner_svg__SVGID_148_'
      gradientUnits='userSpaceOnUse'
      x1={265.379}
      y1={289.286}
      x2={268.854}
      y2={289.286}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M268.6 287.71c.23.33.3.78.24 1.25-.05.39-.2.79-.43 1.16-.11.17-.23.34-.37.49-.82.87-1.89.99-2.39.26-.5-.73-.25-2.02.57-2.89.8-.88 1.87-1 2.38-.27z'
      fill='url(#banner_svg__SVGID_148_)'
    />
    <linearGradient
      id='banner_svg__SVGID_149_'
      gradientUnits='userSpaceOnUse'
      x1={231.612}
      y1={307.881}
      x2={238.892}
      y2={307.881}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M238.89 304.98v1.83c0 .23-.12.44-.32.55l-6.47 3.74a.324.324 0 01-.49-.28v-1.8c0-.27.15-.52.38-.66l6.37-3.68c.24-.14.53.03.53.3z'
      fill='url(#banner_svg__SVGID_149_)'
    />
    <linearGradient
      id='banner_svg__SVGID_150_'
      gradientUnits='userSpaceOnUse'
      x1={162.613}
      y1={267.342}
      x2={166.445}
      y2={267.342}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M166.45 264.91v3.56c0 .72-.86 1.3-1.91 1.3s-1.92-.58-1.92-1.3v-3.56c0 .72.86 1.3 1.92 1.3s1.91-.58 1.91-1.3z'
      fill='url(#banner_svg__SVGID_150_)'
    />
    <linearGradient
      id='banner_svg__SVGID_151_'
      gradientUnits='userSpaceOnUse'
      x1={162.613}
      y1={264.913}
      x2={166.445}
      y2={264.913}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M166.45 264.91c0 .72-.86 1.3-1.91 1.3s-1.92-.58-1.92-1.3c0-.72.86-1.3 1.92-1.3s1.91.59 1.91 1.3z'
      fill='url(#banner_svg__SVGID_151_)'
    />
    <linearGradient
      id='banner_svg__SVGID_152_'
      gradientUnits='userSpaceOnUse'
      x1={219.579}
      y1={234.326}
      x2={223.411}
      y2={234.326}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M223.41 231.9v3.56c0 .72-.86 1.3-1.91 1.3s-1.92-.58-1.92-1.3v-3.56c0 .72.86 1.3 1.92 1.3s1.91-.59 1.91-1.3z'
      fill='url(#banner_svg__SVGID_152_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_8_'
        filterUnits='userSpaceOnUse'
        x={220.94}
        y={231.03}
        width={64.77}
        height={74.17}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={220.94} y={231.03} width={64.77} height={74.17} id='banner_svg__SVGID_153_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_8_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_9_'
            filterUnits='userSpaceOnUse'
            x={220.94}
            y={231.03}
            width={64.77}
            height={74.17}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_154_'
          gradientUnits='userSpaceOnUse'
          x1={220.945}
          y1={268.113}
          x2={285.712}
          y2={268.113}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M221.48 305.2s11.57-43.79-.54-73.86l.54-.31 64.23 37.09-64.23 37.08z'
          mask='url(#banner_svg__SVGID_153_)'
          fill='url(#banner_svg__SVGID_154_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_155_'
      gradientUnits='userSpaceOnUse'
      x1={220.945}
      y1={268.113}
      x2={285.712}
      y2={268.113}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.547} stopColor='#daeaff' />
      <stop offset={0.999} stopColor='#d4e7ff' />
    </linearGradient>
    <path
      d='M221.48 305.2s11.57-43.79-.54-73.86l.54-.31 64.23 37.09-64.23 37.08z'
      fill='url(#banner_svg__SVGID_155_)'
    />
    <linearGradient
      id='banner_svg__SVGID_156_'
      gradientUnits='userSpaceOnUse'
      x1={219.579}
      y1={231.897}
      x2={223.411}
      y2={231.897}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M223.41 231.9c0 .72-.86 1.3-1.91 1.3s-1.92-.58-1.92-1.3c0-.72.86-1.3 1.92-1.3s1.91.58 1.91 1.3z'
      fill='url(#banner_svg__SVGID_156_)'
    />
    <linearGradient
      id='banner_svg__SVGID_157_'
      gradientUnits='userSpaceOnUse'
      x1={278.096}
      y1={266.864}
      x2={281.928}
      y2={266.864}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M281.93 264.44V268c0 .72-.86 1.3-1.91 1.3-1.06 0-1.92-.58-1.92-1.3v-3.56c0 .72.86 1.3 1.92 1.3 1.05 0 1.91-.59 1.91-1.3z'
      fill='url(#banner_svg__SVGID_157_)'
    />
    <linearGradient
      id='banner_svg__SVGID_158_'
      gradientUnits='userSpaceOnUse'
      x1={278.096}
      y1={264.435}
      x2={281.928}
      y2={264.435}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M281.93 264.44c0 .72-.86 1.3-1.91 1.3-1.06 0-1.92-.58-1.92-1.3s.86-1.3 1.92-1.3c1.05-.01 1.91.58 1.91 1.3z'
      fill='url(#banner_svg__SVGID_158_)'
    />
    <linearGradient
      id='banner_svg__SVGID_159_'
      gradientUnits='userSpaceOnUse'
      x1={219.568}
      y1={299.715}
      x2={223.4}
      y2={299.715}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M223.4 297.29v3.56c0 .72-.86 1.3-1.91 1.3s-1.92-.58-1.92-1.3v-3.56c0 .72.86 1.3 1.92 1.3s1.91-.59 1.91-1.3z'
      fill='url(#banner_svg__SVGID_159_)'
    />
    <linearGradient
      id='banner_svg__SVGID_160_'
      gradientUnits='userSpaceOnUse'
      x1={219.568}
      y1={297.286}
      x2={223.4}
      y2={297.286}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M223.4 297.29c0 .72-.86 1.3-1.91 1.3s-1.92-.58-1.92-1.3.86-1.3 1.92-1.3 1.91.58 1.91 1.3z'
      fill='url(#banner_svg__SVGID_160_)'
    />
    <linearGradient
      id='banner_svg__SVGID_161_'
      gradientUnits='userSpaceOnUse'
      x1={157.293}
      y1={318.441}
      x2={221.52}
      y2={318.441}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_161_)' d='M157.29 289.31v21.18l64.23 37.09V326.4z' />
    <linearGradient
      id='banner_svg__SVGID_162_'
      gradientUnits='userSpaceOnUse'
      x1={221.52}
      y1={318.447}
      x2={285.747}
      y2={318.447}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_162_)' d='M221.52 347.58l64.23-37.08v-21.18l-64.23 37.08z' />
    <linearGradient
      id='banner_svg__SVGID_163_'
      gradientUnits='userSpaceOnUse'
      x1={200.441}
      y1={325.398}
      x2={203.915}
      y2={325.398}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M200.7 323.82c-.23.33-.3.78-.24 1.25.05.39.2.79.43 1.16.11.17.23.34.37.49.82.87 1.89.99 2.39.26.5-.73.25-2.02-.57-2.89-.81-.88-1.88-1-2.38-.27z'
      fill='url(#banner_svg__SVGID_163_)'
    />
    <linearGradient
      id='banner_svg__SVGID_164_'
      gradientUnits='userSpaceOnUse'
      x1={206.02}
      y1={328.763}
      x2={209.494}
      y2={328.763}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M206.28 327.18c-.23.33-.3.78-.24 1.25.05.39.2.79.43 1.16.11.17.23.34.37.49.82.87 1.89.99 2.39.26.5-.73.25-2.02-.57-2.89-.81-.87-1.88-.99-2.38-.27z'
      fill='url(#banner_svg__SVGID_164_)'
    />
    <linearGradient
      id='banner_svg__SVGID_165_'
      gradientUnits='userSpaceOnUse'
      x1={211.599}
      y1={332.014}
      x2={215.073}
      y2={332.014}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M211.86 330.43c-.23.33-.3.78-.24 1.25.05.39.2.79.43 1.16.11.17.23.34.37.49.82.87 1.89.99 2.39.26.5-.73.25-2.02-.57-2.89-.81-.87-1.88-.99-2.38-.27z'
      fill='url(#banner_svg__SVGID_165_)'
    />
    <path
      className='banner_svg__st17'
      d='M167.42 305.05v1.83c0 .23.12.44.32.55l6.47 3.74c.22.13.49-.03.49-.28v-1.8c0-.27-.15-.52-.38-.66l-6.37-3.68a.351.351 0 00-.53.3z'
    />
    <linearGradient
      id='banner_svg__SVGID_166_'
      gradientUnits='userSpaceOnUse'
      x1={276.573}
      y1={303.869}
      x2={280.048}
      y2={303.869}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M279.79 302.29c.23.33.3.78.24 1.25-.05.39-.2.79-.43 1.16-.11.17-.23.34-.37.49-.82.87-1.89.99-2.39.26-.5-.73-.25-2.02.57-2.89.8-.88 1.88-1 2.38-.27z'
      fill='url(#banner_svg__SVGID_166_)'
    />
    <linearGradient
      id='banner_svg__SVGID_167_'
      gradientUnits='userSpaceOnUse'
      x1={270.994}
      y1={307.235}
      x2={274.468}
      y2={307.235}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M274.21 305.66c.23.33.3.78.24 1.25-.05.39-.2.79-.43 1.16-.11.17-.23.34-.37.49-.82.87-1.89.99-2.39.26-.5-.73-.25-2.02.57-2.89.8-.88 1.88-1 2.38-.27z'
      fill='url(#banner_svg__SVGID_167_)'
    />
    <linearGradient
      id='banner_svg__SVGID_168_'
      gradientUnits='userSpaceOnUse'
      x1={265.415}
      y1={310.485}
      x2={268.89}
      y2={310.485}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M268.63 308.91c.23.33.3.78.24 1.25-.05.39-.2.79-.43 1.16-.11.17-.23.34-.37.49-.82.87-1.89.99-2.39.26-.5-.73-.25-2.02.57-2.89.81-.88 1.88-1 2.38-.27z'
      fill='url(#banner_svg__SVGID_168_)'
    />
    <path
      className='banner_svg__st17'
      d='M238.93 326.18v1.83c0 .23-.12.44-.32.55l-6.47 3.74a.324.324 0 01-.49-.28v-1.8c0-.27.15-.52.38-.66l6.37-3.68c.23-.14.53.03.53.3z'
    />
    <linearGradient
      id='banner_svg__SVGID_169_'
      gradientUnits='userSpaceOnUse'
      x1={157.258}
      y1={339.611}
      x2={221.486}
      y2={339.611}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_169_)' d='M157.26 310.48v21.18l64.23 37.09v-21.18z' />
    <linearGradient
      id='banner_svg__SVGID_170_'
      gradientUnits='userSpaceOnUse'
      x1={221.486}
      y1={339.616}
      x2={285.712}
      y2={339.616}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_170_)' d='M221.49 368.75l64.22-37.08v-21.18l-64.22 37.08z' />
    <linearGradient
      id='banner_svg__SVGID_171_'
      gradientUnits='userSpaceOnUse'
      x1={200.406}
      y1={346.567}
      x2={203.881}
      y2={346.567}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M200.66 344.99c-.23.33-.3.78-.24 1.25.05.39.2.79.43 1.16.11.17.23.34.37.49.82.87 1.89.99 2.39.26.5-.73.25-2.02-.57-2.89-.8-.88-1.87-1-2.38-.27z'
      fill='url(#banner_svg__SVGID_171_)'
    />
    <linearGradient
      id='banner_svg__SVGID_172_'
      gradientUnits='userSpaceOnUse'
      x1={205.985}
      y1={349.933}
      x2={209.46}
      y2={349.933}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M206.24 348.35c-.23.33-.3.78-.24 1.25.05.39.2.79.43 1.16.11.17.23.34.37.49.82.87 1.89.99 2.39.26.5-.73.25-2.02-.57-2.89-.8-.87-1.87-.99-2.38-.27z'
      fill='url(#banner_svg__SVGID_172_)'
    />
    <linearGradient
      id='banner_svg__SVGID_173_'
      gradientUnits='userSpaceOnUse'
      x1={211.564}
      y1={353.183}
      x2={215.038}
      y2={353.183}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M211.82 351.6c-.23.33-.3.78-.24 1.25.05.39.2.79.43 1.16.11.17.23.34.37.49.82.87 1.89.99 2.39.26.5-.73.25-2.02-.57-2.89-.8-.87-1.87-.99-2.38-.27z'
      fill='url(#banner_svg__SVGID_173_)'
    />
    <linearGradient
      id='banner_svg__SVGID_174_'
      gradientUnits='userSpaceOnUse'
      x1={167.387}
      y1={329.124}
      x2={174.667}
      y2={329.124}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M167.39 326.22v1.83c0 .23.12.44.32.55l6.47 3.74c.22.13.49-.03.49-.28v-1.8c0-.27-.15-.52-.38-.66l-6.37-3.68a.35.35 0 00-.53.3z'
      fill='url(#banner_svg__SVGID_174_)'
    />
    <linearGradient
      id='banner_svg__SVGID_175_'
      gradientUnits='userSpaceOnUse'
      x1={276.538}
      y1={325.039}
      x2={280.013}
      y2={325.039}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M279.75 323.46c.23.33.3.78.24 1.25-.05.39-.2.79-.43 1.16-.11.17-.23.34-.37.49-.82.87-1.89.99-2.39.26-.5-.73-.25-2.02.57-2.89.81-.88 1.88-1 2.38-.27z'
      fill='url(#banner_svg__SVGID_175_)'
    />
    <linearGradient
      id='banner_svg__SVGID_176_'
      gradientUnits='userSpaceOnUse'
      x1={270.959}
      y1={328.404}
      x2={274.433}
      y2={328.404}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M274.17 326.82c.23.33.3.78.24 1.25-.05.39-.2.79-.43 1.16-.11.17-.23.34-.37.49-.82.87-1.89.99-2.39.26-.5-.73-.25-2.02.57-2.89.81-.87 1.88-.99 2.38-.27z'
      fill='url(#banner_svg__SVGID_176_)'
    />
    <linearGradient
      id='banner_svg__SVGID_177_'
      gradientUnits='userSpaceOnUse'
      x1={265.38}
      y1={331.655}
      x2={268.855}
      y2={331.655}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M268.6 330.07c.23.33.3.78.24 1.25-.05.39-.2.79-.43 1.16-.11.17-.23.34-.37.49-.82.87-1.89.99-2.39.26-.5-.73-.25-2.02.57-2.89.8-.87 1.87-.99 2.38-.27z'
      fill='url(#banner_svg__SVGID_177_)'
    />
    <linearGradient
      id='banner_svg__SVGID_178_'
      gradientUnits='userSpaceOnUse'
      x1={231.613}
      y1={350.25}
      x2={238.893}
      y2={350.25}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M238.89 347.35v1.83c0 .23-.12.44-.32.55l-6.47 3.74a.324.324 0 01-.49-.28v-1.8c0-.27.15-.52.38-.66l6.37-3.68c.24-.15.53.03.53.3z'
      fill='url(#banner_svg__SVGID_178_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_10_'
        filterUnits='userSpaceOnUse'
        x={41.92}
        y={295.98}
        width={83.48}
        height={43.74}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={41.92} y={295.98} width={83.48} height={43.74} id='banner_svg__SVGID_179_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_10_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_11_'
            filterUnits='userSpaceOnUse'
            x={41.92}
            y={295.98}
            width={83.48}
            height={43.74}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_180_'
          gradientUnits='userSpaceOnUse'
          x1={43.213}
          y1={321.39}
          x2={126.918}
          y2={321.39}
          gradientTransform='rotate(2.807 156.118 291.165)'
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M125.4 321.18l-3.91 5.24-.19.25-2.38 3.18-9.25-1.84c-1.57 1.08-3.36 2.05-5.33 2.89l3.59 4.27.41.49-2.29.54-13.97 3.27-4.03-4.8c-2.48.19-5.01.23-7.54.11l-3.68 4.94-15.21-3.03-.62-.12-.69-.14.11-.15 3.57-4.78c-2.03-.8-3.85-1.7-5.45-2.7l-9.23 2.16-2.77-3.3-4.33-5.16 3.72-.87 5.44-1.27a8.966 8.966 0 01-.19-3.99l-5.46-1.09-3.79-.76 6.48-8.67 9.15 1.82c.35-.25.72-.49 1.09-.73.13-.09.25-.16.39-.25.49-.29.99-.58 1.51-.86.75-.4 1.54-.78 2.36-1.14l-.47-.56-3.45-4.11 16.26-3.81 3.88 4.63c.99-.08 1.99-.14 3-.17 1.37-.05 2.75-.04 4.14.01.23.01.47.01.7.03l1-1.33.07-.1.34-.45 2.13-2.85 3.32.66 13.19 2.63-3.53 4.74c1.14.45 2.22.93 3.23 1.45.85.43 1.66.89 2.4 1.37l8.9-2.08 7.1 8.46-4.64 1.08-4.34 1.01c.34 1.33.39 2.69.11 4.05l4.83.96 4.32.87z'
          mask='url(#banner_svg__SVGID_179_)'
          fill='url(#banner_svg__SVGID_180_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_181_'
      gradientUnits='userSpaceOnUse'
      x1={43.213}
      y1={321.39}
      x2={126.918}
      y2={321.39}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.999} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M125.4 321.18l-3.91 5.24-.19.25-2.38 3.18-9.25-1.84c-1.57 1.08-3.36 2.05-5.33 2.89l3.59 4.27.41.49-2.29.54-13.97 3.27-4.03-4.8c-2.48.19-5.01.23-7.54.11l-3.68 4.94-15.21-3.03-.62-.12-.69-.14.11-.15 3.57-4.78c-2.03-.8-3.85-1.7-5.45-2.7l-9.23 2.16-2.77-3.3-4.33-5.16 3.72-.87 5.44-1.27a8.966 8.966 0 01-.19-3.99l-5.46-1.09-3.79-.76 6.48-8.67 9.15 1.82c.35-.25.72-.49 1.09-.73.13-.09.25-.16.39-.25.49-.29.99-.58 1.51-.86.75-.4 1.54-.78 2.36-1.14l-.47-.56-3.45-4.11 16.26-3.81 3.88 4.63c.99-.08 1.99-.14 3-.17 1.37-.05 2.75-.04 4.14.01.23.01.47.01.7.03l1-1.33.07-.1.34-.45 2.13-2.85 3.32.66 13.19 2.63-3.53 4.74c1.14.45 2.22.93 3.23 1.45.85.43 1.66.89 2.4 1.37l8.9-2.08 7.1 8.46-4.64 1.08-4.34 1.01c.34 1.33.39 2.69.11 4.05l4.83.96 4.32.87z'
      fill='url(#banner_svg__SVGID_181_)'
    />
    <linearGradient
      id='banner_svg__SVGID_182_'
      gradientUnits='userSpaceOnUse'
      x1={43.213}
      y1={311.415}
      x2={126.917}
      y2={311.415}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M125.89 311.22l-3.91 5.24-.19.25-2.38 3.18-9.25-1.84c-1.57 1.08-3.36 2.05-5.33 2.89l3.59 4.27.41.49-2.29.54-13.97 3.27-4.03-4.8c-2.48.19-5.01.23-7.54.11l-3.68 4.94-15.21-3.03-.62-.12-.69-.14.11-.15 3.57-4.78c-2.03-.8-3.85-1.7-5.45-2.7L49.8 321l-2.77-3.3-4.33-5.16 3.72-.87 5.44-1.27a8.966 8.966 0 01-.19-3.99l-5.46-1.09-3.79-.76 6.48-8.67 9.15 1.82c.35-.25.72-.49 1.09-.73.13-.09.25-.16.39-.25.49-.29.99-.58 1.51-.86.75-.4 1.54-.78 2.36-1.14l-.47-.56-3.45-4.11 16.26-3.81 3.88 4.63c.99-.08 1.99-.14 3-.17 1.37-.05 2.75-.04 4.14.01.23.01.47.01.7.03l1-1.33.07-.1.34-.45 2.13-2.85 3.32.66 13.19 2.63-3.53 4.74c1.14.45 2.22.93 3.23 1.45.85.43 1.66.89 2.4 1.37l8.9-2.08 7.1 8.46-4.64 1.08-4.34 1.01c.34 1.33.39 2.69.11 4.05l4.83.96 4.32.87z'
      fill='url(#banner_svg__SVGID_182_)'
    />
    <linearGradient
      id='banner_svg__SVGID_183_'
      gradientUnits='userSpaceOnUse'
      x1={71.032}
      y1={315.29}
      x2={97.245}
      y2={315.29}
      gradientTransform='rotate(.81 629.246 306.931)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M96.28 310.41c-2.64 3.54-10.25 5.32-16.98 3.98-6.74-1.34-10.06-5.3-7.42-8.84 2.64-3.54 10.25-5.32 16.98-3.98 6.74 1.35 10.06 5.3 7.42 8.84z'
      fill='url(#banner_svg__SVGID_183_)'
    />
    <path d='M108.42 325.21l1.43-.92c-.46.32-.94.63-1.43.92z' fill='none' stroke='#000' strokeMiterlimit={10} />
    <linearGradient
      id='banner_svg__SVGID_184_'
      gradientUnits='userSpaceOnUse'
      x1={59.539}
      y1={298.295}
      x2={63.681}
      y2={298.295}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path
      d='M63.39 294.74c-.82.36-1.61.74-2.36 1.14-.52.28-1.02.56-1.51.86l-.39.24.34-6.91 3.92 4.67z'
      fill='url(#banner_svg__SVGID_184_)'
    />
    <linearGradient
      id='banner_svg__SVGID_185_'
      gradientUnits='userSpaceOnUse'
      x1={104.179}
      y1={294.801}
      x2={107.477}
      y2={294.801}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path d='M107.51 289.31l-.31 6.18c-1.01-.52-2.08-1-3.23-1.44l3.54-4.74z' fill='url(#banner_svg__SVGID_185_)' />
    <linearGradient
      id='banner_svg__SVGID_186_'
      gradientUnits='userSpaceOnUse'
      x1={117.379}
      y1={308.383}
      x2={126.235}
      y2={308.383}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path
      d='M125.6 303.24l-.31 6.25-3.72.87-4.83-.96c.28-1.36.23-2.72-.11-4.05l4.33-1.01 4.64-1.1z'
      fill='url(#banner_svg__SVGID_186_)'
    />
    <linearGradient
      id='banner_svg__SVGID_187_'
      gradientUnits='userSpaceOnUse'
      x1={120.875}
      y1={320.302}
      x2={126.917}
      y2={320.302}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_187_)' d='M125.89 311.22l-.31 6.24-6.47 8.68.3-6.25 2.38-3.18z' />
    <linearGradient
      id='banner_svg__SVGID_188_'
      gradientUnits='userSpaceOnUse'
      x1={111.538}
      y1={324.101}
      x2={120.875}
      y2={324.101}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_188_)' d='M119.41 319.89l-.3 6.25-9.26-1.84.31-6.25z' />
    <linearGradient
      id='banner_svg__SVGID_189_'
      gradientUnits='userSpaceOnUse'
      x1={106.364}
      y1={323.903}
      x2={111.538}
      y2={323.903}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M110.16 318.05l-.31 6.25-1.43.92-3.58-4.27c1.96-.85 3.75-1.82 5.32-2.9z'
      fill='url(#banner_svg__SVGID_189_)'
    />
    <linearGradient
      id='banner_svg__SVGID_190_'
      gradientUnits='userSpaceOnUse'
      x1={94.541}
      y1={333.425}
      x2={110.592}
      y2={333.425}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_190_)' d='M108.83 325.71l-.31 6.24-16.26 3.81.31-6.24 13.98-3.28z' />
    <linearGradient
      id='banner_svg__SVGID_191_'
      gradientUnits='userSpaceOnUse'
      x1={90.277}
      y1={333.429}
      x2={94.541}
      y2={333.429}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_191_)' d='M92.57 329.52l-.31 6.24-4.03-4.8.31-6.25z' />
    <linearGradient
      id='banner_svg__SVGID_192_'
      gradientUnits='userSpaceOnUse'
      x1={82.749}
      y1={331.37}
      x2={90.277}
      y2={331.37}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M88.54 324.71l-.31 6.24c-2.48.19-5 .23-7.54.11l.31-6.24c2.54.12 5.06.08 7.54-.11z'
      fill='url(#banner_svg__SVGID_192_)'
    />
    <linearGradient
      id='banner_svg__SVGID_193_'
      gradientUnits='userSpaceOnUse'
      x1={79.314}
      y1={334.167}
      x2={82.749}
      y2={334.167}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_193_)' d='M81 324.82l-.31 6.25-3.68 4.93.3-6.24z' />
    <linearGradient
      id='banner_svg__SVGID_194_'
      gradientUnits='userSpaceOnUse'
      x1={60.519}
      y1={327.277}
      x2={66.091}
      y2={327.277}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M64.48 321.54l-3.57 4.78c-.77-.39-1.5-.81-2.18-1.24l.31-6.25c1.58 1 3.41 1.91 5.44 2.71z'
      fill='url(#banner_svg__SVGID_194_)'
    />
    <linearGradient
      id='banner_svg__SVGID_195_'
      gradientUnits='userSpaceOnUse'
      x1={62.654}
      y1={335.486}
      x2={79.314}
      y2={335.486}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_195_)' d='M77.31 329.76l-.3 6.24-16.52-3.29.31-6.24.69.14.61.12z' />
    <linearGradient
      id='banner_svg__SVGID_196_'
      gradientUnits='userSpaceOnUse'
      x1={51.405}
      y1={328.022}
      x2={60.519}
      y2={328.022}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_196_)' d='M59.04 318.84l-.31 6.24-9.24 2.16.31-6.24z' />
    <linearGradient
      id='banner_svg__SVGID_197_'
      gradientUnits='userSpaceOnUse'
      x1={43.896}
      y1={325.279}
      x2={51.405}
      y2={325.279}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_197_)' d='M49.8 321l-.31 6.24-7.1-8.46.31-6.24 4.33 5.16z' />
    <linearGradient
      id='banner_svg__SVGID_198_'
      gradientUnits='userSpaceOnUse'
      x1={43.214}
      y1={313.597}
      x2={52.937}
      y2={313.597}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#0029e9' />
    </linearGradient>
    <path
      d='M51.86 310.39l-5.44 1.27-4.32-.86.31-6.25 3.79.75 5.47 1.09a8.99 8.99 0 00.19 4z'
      fill='url(#banner_svg__SVGID_198_)'
    />
    <linearGradient
      id='banner_svg__SVGID_199_'
      gradientUnits='userSpaceOnUse'
      x1={59.54}
      y1={303.673}
      x2={126.917}
      y2={303.673}
      gradientTransform='rotate(2.807 156.118 291.165)'
    >
      <stop offset={0} stopColor='#d4e3ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M125.89 311.22l-3.91 5.24c-18.69-17.27-40.93-21.99-59.06-22.28l-3.45-4.11 16.26-3.81 3.88 4.63c.99-.08 1.99-.14 3-.17 1.37-.05 2.75-.04 4.14.01.23.01.47.01.7.03l1-1.33.07-.1.34-.45 2.13-2.85 3.32.66 13.19 2.63-3.53 4.74c1.14.45 2.22.93 3.23 1.45.85.43 1.66.89 2.4 1.37l8.9-2.08 7.1 8.46-4.64 1.08-4.34 1.01c.34 1.33.39 2.69.11 4.05l4.83.96 4.33.86z'
      fill='url(#banner_svg__SVGID_199_)'
    />
    <linearGradient
      id='banner_svg__SVGID_200_'
      gradientUnits='userSpaceOnUse'
      x1={1268.363}
      y1={300.751}
      x2={1308.826}
      y2={300.751}
      gradientTransform='matrix(-1 0 0 1 1636.334 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_200_)' d='M327.51 296.87l13.44-7.76 27.02 15.44-13.58 7.84z' />
    <linearGradient
      id='banner_svg__SVGID_201_'
      gradientUnits='userSpaceOnUse'
      x1={1272.974}
      y1={318.86}
      x2={1331.936}
      y2={318.86}
      gradientTransform='matrix(-1 0 0 1 1636.334 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_201_)' d='M318.55 302.04l-14.15 8.17 45.52 25.46 13.44-7.76z' />
    <linearGradient
      id='banner_svg__SVGID_202_'
      gradientUnits='userSpaceOnUse'
      x1={1255.051}
      y1={285.189}
      x2={1286.42}
      y2={285.189}
      gradientTransform='matrix(-1 0 0 1 1636.334 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_202_)' d='M349.91 283.93l18.06 10.28 13.31-7.69-17.92-10.35z' />
    <linearGradient
      id='banner_svg__SVGID_203_'
      gradientUnits='userSpaceOnUse'
      x1={185.023}
      y1={173.184}
      x2={186.897}
      y2={173.184}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M186.9 180.01l-.6.63-1.27-2.06s.13-1.89.52-4.87c.38-2.98.18-6.26.18-6.26l1.01-1.71c.11 1.08.16 2.25.08 3.72-.18 3.62-.39 5.19-.22 8.09.06.85.17 1.69.3 2.46z'
      fill='url(#banner_svg__SVGID_203_)'
    />
    <path
      className='banner_svg__st129'
      d='M199.02 218.74s.62 1.53.49 3.26c-.14 1.73-1.51.84-5.23.02-2.43-.53-4.42-1.11-4.12-1.9.3-.79 5.5-1.54 5.5-1.54l1.65-.17 1.71.33zM194.38 223.32s.63 1.55.49 3.31-1.53.85-5.3.02c-2.46-.54-4.48-1.12-4.18-1.92.31-.8 5.58-1.56 5.58-1.56l1.67-.17 1.74.32z'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_12_'
        filterUnits='userSpaceOnUse'
        x={192.78}
        y={175.4}
        width={10.19}
        height={43.77}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={192.78} y={175.4} width={10.19} height={43.77} id='banner_svg__SVGID_204_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_12_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_13_'
            filterUnits='userSpaceOnUse'
            x={192.78}
            y={175.4}
            width={10.19}
            height={43.77}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_205_'
          gradientUnits='userSpaceOnUse'
          x1={192.78}
          y1={197.28}
          x2={202.975}
          y2={197.28}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M202.97 177s.18 3.94-1.11 5.86c-1.29 1.91-2.7 9.29-2.84 16.59-.13 7.29 0 19.3 0 19.3s-.86.52-1.88.4-1.48-.55-1.48-.55l-2.88-39.81 1.18-3.39 9.01 1.6z'
          mask='url(#banner_svg__SVGID_204_)'
          fill='url(#banner_svg__SVGID_205_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_206_'
      gradientUnits='userSpaceOnUse'
      x1={192.78}
      y1={197.28}
      x2={202.975}
      y2={197.28}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.547} stopColor='#daeaff' />
      <stop offset={0.999} stopColor='#d4e7ff' />
    </linearGradient>
    <path
      d='M202.97 177s.18 3.94-1.11 5.86c-1.29 1.91-2.7 9.29-2.84 16.59-.13 7.29 0 19.3 0 19.3s-.86.52-1.88.4-1.48-.55-1.48-.55l-2.88-39.81 1.18-3.39 9.01 1.6z'
      fill='url(#banner_svg__SVGID_206_)'
    />
    <path
      className='banner_svg__st17'
      d='M198.39 180.96s.19 4-1.13 5.94c-1.31 1.94-2.74 9.43-2.88 16.83s0 19.58 0 19.58-.88.53-1.91.41c-1.03-.13-1.5-.56-1.5-.56l-2.92-40.39 1.2-3.44 9.14 1.63z'
    />
    <linearGradient
      id='banner_svg__SVGID_207_'
      gradientUnits='userSpaceOnUse'
      x1={184.688}
      y1={148.996}
      x2={189.621}
      y2={148.996}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M188.93 149.2c-.86.81-.98 1.99-.98 1.99s-.51.73-.8.68c-.28-.04-2.28-.93-2.28-.93s.59-.98.11-1.57c-.48-.59-.26-1.12-.09-1.48.1-.2.52-.64.98-1.04.59-.5 1.25-.93 1.37-.67.22.46-.86 1.69-.62 1.71h.01c.68 0 1.81-.04 2.97-.34 0 .01.19.84-.67 1.65z'
      fill='url(#banner_svg__SVGID_207_)'
    />
    <linearGradient
      id='banner_svg__SVGID_208_'
      gradientUnits='userSpaceOnUse'
      x1={183.907}
      y1={158.841}
      x2={188.281}
      y2={158.841}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M185.15 166.84s-1.18-2.31-1.22-8.67c-.04-6.36 0-6.98 0-6.98s1.04-.39 2.16-.35c1.11.04 2.2.46 2.2.46l-.24 4.43-2.9 11.11z'
      fill='url(#banner_svg__SVGID_208_)'
    />
    <linearGradient
      id='banner_svg__SVGID_209_'
      gradientUnits='userSpaceOnUse'
      x1={189.962}
      y1={149.025}
      x2={194.171}
      y2={149.025}
    >
      <stop offset={0} stopColor='#ffd4d5' />
      <stop offset={1} stopColor='#ff7f83' />
    </linearGradient>
    <path
      d='M189.97 147.46s.17 1.87 0 3.03c-.17 1.16 1.74 2.1 1.81 2.06.07-.04 2.39-2.43 2.39-2.43l-.56-3.52s-.07-.59-.24-.65c-.17-.07-2.53-.46-2.53-.46l-.87 1.97z'
      fill='url(#banner_svg__SVGID_209_)'
    />
    <linearGradient
      id='banner_svg__SVGID_210_'
      gradientUnits='userSpaceOnUse'
      x1={185.832}
      y1={143.749}
      x2={193.375}
      y2={143.749}
    >
      <stop offset={0} stopColor='#ffd4d5' />
      <stop offset={1} stopColor='#ff7f83' />
    </linearGradient>
    <path
      d='M193.01 145.33c-.73 1.25-2.08 1.9-3.4 2.23-1.16.29-2.29.34-2.97.34h-.01c-.25-.01.84-1.25.62-1.71-.12-.26-.78.17-1.37.67-.05-.69-.06-1.65.12-2.63.35-1.86.73-2.82.73-2.82l2.93-1.81c-.01 0 5.1 2.75 3.35 5.73z'
      fill='url(#banner_svg__SVGID_210_)'
    />
    <linearGradient
      id='banner_svg__SVGID_211_'
      gradientUnits='userSpaceOnUse'
      x1={186.082}
      y1={166.015}
      x2={202.987}
      y2={166.015}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M202.98 177.27s-.64 2.34-4.39 4.21c-3.75 1.87-10.97 1.7-10.97 1.7s-.42-1.36-.73-3.17c-.13-.77-.24-1.61-.29-2.47-.18-2.9.04-4.47.22-8.09.07-1.47.02-2.64-.08-3.72-.16-1.57-.44-2.95-.62-4.8-.31-3.11 1.36-7.98 2.41-9.26 1.05-1.27 4.23-2.44 4.23-2.44s2.26-.52 4.07-.37c.73.06 1.34.53 1.79 1.34 0 0 .01.01.02.04.06.11.12.22.17.34 0 .01.01.03.02.05.46 1.07.71 2.59.7 4.5 0 .27 0 .55-.02.84-.12 2.93.54 5.74 1.33 8.48.37 1.3.77 2.58 1.13 3.86 1.13 3.99 1.01 8.96 1.01 8.96z'
      fill='url(#banner_svg__SVGID_211_)'
    />
    <linearGradient
      id='banner_svg__SVGID_212_'
      gradientUnits='userSpaceOnUse'
      x1={190.278}
      y1={154.825}
      x2={185.492}
      y2={166.694}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M191.49 153.89c-.54-1.32-2.19-1.77-3.28-.86-.65.54-1.34 1.48-1.91 3.09-1.62 4.56-2.35 10.39-.58 11.32 3.69 1.93 5.18-6.19 5.97-9.87.35-1.64.14-2.84-.2-3.68z'
      fill='url(#banner_svg__SVGID_212_)'
    />
    <linearGradient
      id='banner_svg__SVGID_213_'
      gradientUnits='userSpaceOnUse'
      x1={198.845}
      y1={157.553}
      x2={202.39}
      y2={157.553}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M200.86 164.46c-.79-2.74-1.45-5.55-1.33-8.48.09-2.29-.16-4.11-.68-5.33.71 1.5 3.03 6.52 3.5 9.5.25 1.67-.56 3.18-1.49 4.31z'
      fill='url(#banner_svg__SVGID_213_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_14_'
        filterUnits='userSpaceOnUse'
        x={194.54}
        y={155.14}
        width={8.45}
        height={26.34}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={194.54} y={155.14} width={8.45} height={26.34} id='banner_svg__SVGID_214_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_14_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_15_'
            filterUnits='userSpaceOnUse'
            x={194.54}
            y={155.14}
            width={8.45}
            height={26.34}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_215_'
          gradientUnits='userSpaceOnUse'
          x1={194.541}
          y1={168.314}
          x2={202.987}
          y2={168.314}
        >
          <stop offset={0} stopColor='#999' />
          <stop offset={0.202} stopColor='#b4b4b4' />
          <stop offset={0.554} stopColor='#dcdcdc' />
          <stop offset={0.832} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M202.98 177.27s-.64 2.34-4.39 4.21c3.48-3.27 2.31-8.83-2.17-9.54-4.48-.7.21-4.65 1.85-5.94 1.63-1.28-.09-4.42-2.54-4.51-2.45-.09 3.82-6.36 3.82-6.36 0 .27 0 .55-.02.84-.12 2.93.54 5.74 1.33 8.48.37 1.3.77 2.58 1.13 3.86 1.11 3.99.99 8.96.99 8.96z'
          mask='url(#banner_svg__SVGID_214_)'
          fill='url(#banner_svg__SVGID_215_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_216_'
      gradientUnits='userSpaceOnUse'
      x1={194.541}
      y1={168.314}
      x2={202.987}
      y2={168.314}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M202.98 177.27s-.64 2.34-4.39 4.21c3.48-3.27 2.31-8.83-2.17-9.54-4.48-.7.21-4.65 1.85-5.94 1.63-1.28-.09-4.42-2.54-4.51-2.45-.09 3.82-6.36 3.82-6.36 0 .27 0 .55-.02.84-.12 2.93.54 5.74 1.33 8.48.37 1.3.77 2.58 1.13 3.86 1.11 3.99.99 8.96.99 8.96z'
      fill='url(#banner_svg__SVGID_216_)'
    />
    <path
      className='banner_svg__st129'
      d='M185.37 136.67s-.67 3.07 1.35 4.74c2.02 1.67 2.03 1.9 2.21 2.63.19.73-.19 1.46-.19 1.46s1.52-1.61 1.93-1.08c.41.53-.62 2.08.8 2.4 1.42.32 3.72-.56 4.46-2.14.73-1.58.45-3.06.45-3.06s.18-2.9-1.49-4.09c-1.22-.87-1.48-.15-2.47-.7-.34-.19-.04-1.29-1.31-1.29-.96 0-1.37 1.11-2.49.58-.9-.43.3-1.65-.93-2.21s-2.32 2.76-2.32 2.76z'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_16_'
        filterUnits='userSpaceOnUse'
        x={381.11}
        y={146.21}
        width={1.65}
        height={103.16}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={381.11} y={146.21} width={1.65} height={103.16} id='banner_svg__SVGID_217_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_16_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_17_'
            filterUnits='userSpaceOnUse'
            x={381.11}
            y={146.21}
            width={1.65}
            height={103.16}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_218_'
          gradientUnits='userSpaceOnUse'
          x1={381.111}
          y1={197.793}
          x2={382.761}
          y2={197.793}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M381.11 146.21s.57 53.19.83 103.16c0 0 .58-80.49.83-103.16h-1.66z'
          mask='url(#banner_svg__SVGID_217_)'
          fill='url(#banner_svg__SVGID_218_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_219_'
      gradientUnits='userSpaceOnUse'
      x1={381.111}
      y1={197.793}
      x2={382.761}
      y2={197.793}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M381.11 146.21s.57 53.19.83 103.16c0 0 .58-80.49.83-103.16h-1.66z' fill='url(#banner_svg__SVGID_219_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_18_'
        filterUnits='userSpaceOnUse'
        x={375.83}
        y={135.27}
        width={12.21}
        height={12.21}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={375.83} y={135.27} width={12.21} height={12.21} id='banner_svg__SVGID_220_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_18_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_19_'
            filterUnits='userSpaceOnUse'
            x={375.83}
            y={135.27}
            width={12.21}
            height={12.21}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_221_'
          gradientUnits='userSpaceOnUse'
          x1={388.043}
          y1={141.379}
          x2={375.829}
          y2={141.379}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={381.94}
          cy={141.38}
          r={6.11}
          mask='url(#banner_svg__SVGID_220_)'
          fill='url(#banner_svg__SVGID_221_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_222_'
      gradientUnits='userSpaceOnUse'
      x1={388.043}
      y1={141.379}
      x2={375.829}
      y2={141.379}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={381.94} cy={141.38} r={6.11} fill='url(#banner_svg__SVGID_222_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_20_'
        filterUnits='userSpaceOnUse'
        x={139.49}
        y={288.1}
        width={1.65}
        height={93.73}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={139.49} y={288.1} width={1.65} height={93.73} id='banner_svg__SVGID_223_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_20_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_21_'
            filterUnits='userSpaceOnUse'
            x={139.49}
            y={288.1}
            width={1.65}
            height={93.73}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_224_'
          gradientUnits='userSpaceOnUse'
          x1={139.49}
          y1={334.964}
          x2={141.141}
          y2={334.964}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M139.49 288.1s.57 48.33.83 93.73c0 0 .58-73.13.83-93.73h-1.66z'
          mask='url(#banner_svg__SVGID_223_)'
          fill='url(#banner_svg__SVGID_224_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_225_'
      gradientUnits='userSpaceOnUse'
      x1={139.49}
      y1={334.964}
      x2={141.141}
      y2={334.964}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M139.49 288.1s.57 48.33.83 93.73c0 0 .58-73.13.83-93.73h-1.66z' fill='url(#banner_svg__SVGID_225_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_22_'
        filterUnits='userSpaceOnUse'
        x={134.21}
        y={277.16}
        width={12.21}
        height={12.21}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={134.21} y={277.16} width={12.21} height={12.21} id='banner_svg__SVGID_226_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_22_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_23_'
            filterUnits='userSpaceOnUse'
            x={134.21}
            y={277.16}
            width={12.21}
            height={12.21}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_227_'
          gradientUnits='userSpaceOnUse'
          x1={146.423}
          y1={283.264}
          x2={134.208}
          y2={283.264}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={140.32}
          cy={283.26}
          r={6.11}
          mask='url(#banner_svg__SVGID_226_)'
          fill='url(#banner_svg__SVGID_227_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_228_'
      gradientUnits='userSpaceOnUse'
      x1={146.423}
      y1={283.264}
      x2={134.208}
      y2={283.264}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={140.32} cy={283.26} r={6.11} fill='url(#banner_svg__SVGID_228_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_24_'
        filterUnits='userSpaceOnUse'
        x={25.92}
        y={194.4}
        width={1.65}
        height={93.73}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={25.92} y={194.4} width={1.65} height={93.73} id='banner_svg__SVGID_229_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_24_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_25_'
            filterUnits='userSpaceOnUse'
            x={25.92}
            y={194.4}
            width={1.65}
            height={93.73}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_230_'
          gradientUnits='userSpaceOnUse'
          x1={25.919}
          y1={241.264}
          x2={27.569}
          y2={241.264}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M25.92 194.4s.57 48.33.83 93.73c0 0 .58-73.13.83-93.73h-1.66z'
          mask='url(#banner_svg__SVGID_229_)'
          fill='url(#banner_svg__SVGID_230_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_231_'
      gradientUnits='userSpaceOnUse'
      x1={25.919}
      y1={241.264}
      x2={27.569}
      y2={241.264}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M25.92 194.4s.57 48.33.83 93.73c0 0 .58-73.13.83-93.73h-1.66z' fill='url(#banner_svg__SVGID_231_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_26_'
        filterUnits='userSpaceOnUse'
        x={20.64}
        y={183.46}
        width={12.21}
        height={12.21}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={20.64} y={183.46} width={12.21} height={12.21} id='banner_svg__SVGID_232_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_26_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_27_'
            filterUnits='userSpaceOnUse'
            x={20.64}
            y={183.46}
            width={12.21}
            height={12.21}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_233_'
          gradientUnits='userSpaceOnUse'
          x1={32.851}
          y1={189.564}
          x2={20.636}
          y2={189.564}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={26.74}
          cy={189.56}
          r={6.11}
          mask='url(#banner_svg__SVGID_232_)'
          fill='url(#banner_svg__SVGID_233_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_234_'
      gradientUnits='userSpaceOnUse'
      x1={32.851}
      y1={189.564}
      x2={20.636}
      y2={189.564}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={26.74} cy={189.56} r={6.11} fill='url(#banner_svg__SVGID_234_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_28_'
        filterUnits='userSpaceOnUse'
        x={266.29}
        y={118.18}
        width={1.65}
        height={56.23}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={266.29} y={118.18} width={1.65} height={56.23} id='banner_svg__SVGID_235_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_28_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_29_'
            filterUnits='userSpaceOnUse'
            x={266.29}
            y={118.18}
            width={1.65}
            height={56.23}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_236_'
          gradientUnits='userSpaceOnUse'
          x1={266.291}
          y1={146.294}
          x2={267.942}
          y2={146.294}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M266.29 118.18s.57 28.99.83 56.23c0 0 .58-43.88.83-56.23h-1.66z'
          mask='url(#banner_svg__SVGID_235_)'
          fill='url(#banner_svg__SVGID_236_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_237_'
      gradientUnits='userSpaceOnUse'
      x1={266.291}
      y1={146.294}
      x2={267.942}
      y2={146.294}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M266.29 118.18s.57 28.99.83 56.23c0 0 .58-43.88.83-56.23h-1.66z' fill='url(#banner_svg__SVGID_237_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_30_'
        filterUnits='userSpaceOnUse'
        x={261.01}
        y={107.24}
        width={12.21}
        height={12.21}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={261.01} y={107.24} width={12.21} height={12.21} id='banner_svg__SVGID_238_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_30_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_31_'
            filterUnits='userSpaceOnUse'
            x={261.01}
            y={107.24}
            width={12.21}
            height={12.21}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_239_'
          gradientUnits='userSpaceOnUse'
          x1={273.224}
          y1={113.344}
          x2={261.009}
          y2={113.344}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={267.12}
          cy={113.34}
          r={6.11}
          mask='url(#banner_svg__SVGID_238_)'
          fill='url(#banner_svg__SVGID_239_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_240_'
      gradientUnits='userSpaceOnUse'
      x1={273.224}
      y1={113.344}
      x2={261.009}
      y2={113.344}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={267.12} cy={113.34} r={6.11} fill='url(#banner_svg__SVGID_240_)' />
  </svg>
)

export default SvgBanner
